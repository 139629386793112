import { createSelector } from 'reselect';
import { AppState } from '../store';
import { LenderBudgetConfigState } from './state';

const lenderBudgetConfigSelector = (appState: AppState) => appState.lenderBudgetConfig!;

export const lenderBudgetsLoading = createSelector(
  lenderBudgetConfigSelector,
  (lenderBudgetConfig: LenderBudgetConfigState) => lenderBudgetConfig.loading
);

export const lenderBudgetsError = createSelector(
  lenderBudgetConfigSelector,
  (lenderBudgetConfig: LenderBudgetConfigState) => lenderBudgetConfig.error
);

export const lenderBudgetsSelector = createSelector(
  lenderBudgetConfigSelector,
  (lenderBudgetConfig: LenderBudgetConfigState) => lenderBudgetConfig.budgets
);

export const lenderBudgetSelector = (lenderId: string) =>
  createSelector(
    lenderBudgetConfigSelector,
    (lenderBudgetConfig: LenderBudgetConfigState) => lenderBudgetConfig.budgets[lenderId]
  );

export const enabledLendersSelector = createSelector(
  lenderBudgetConfigSelector,
  (lenderBudgetConfig: LenderBudgetConfigState) => Object.values(lenderBudgetConfig.enabledLenders)
);
