import { ConditionReference } from 'json-rules-engine';
import React from 'react';
import { camelCaseToStartCase } from './utils';

interface ConditionReferenceNodeProps {
  data: ConditionReference;
}

export const ConditionReferenceNode: React.FC<ConditionReferenceNodeProps> = ({ data }) => {
  return <>{camelCaseToStartCase(data.condition)}</>;
};
