import { useCallback, useState } from 'react';
import { loggedInFetch } from '../../modules/authentication';

export type SentLead =
  | {
      status: 'Sending';
    }
  | {
      status: 'Success';
      request: unknown;
      response: unknown;
    }
  | {
      status: 'Failed';
      request: unknown;
      response: unknown;
    }
  | {
      status: 'None';
    };

export const useSendLead = (): [SentLead, (data: unknown) => void] => {
  const [sent, setSent] = useState<SentLead>({ status: 'None' });
  const send = useCallback(
    (data: unknown) => {
      setSent({ status: 'Sending' });
      loggedInFetch('/api/lender/configuration/leads/test', {
        headers: {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(data)
      })
        .then(async (result) => {
          setSent({
            status: result.ok ? 'Success' : 'Failed',
            request: data,
            response: await result.json()
          });
        })
        .catch((err) =>
          setSent({ status: 'Failed', request: data, response: { message: err.toString() } })
        );
      return;
    },
    [setSent]
  );
  return [sent, send];
};
