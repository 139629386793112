import { OwnUpDropdown, OwnUpMenuItem } from '@rategravity/own-up-component-library';
import React from 'react';

type EnumeratedEditorProps = {
  name: string;
  values: (string | number)[];
  data: string | number | undefined;
  optional: boolean;
  onDataUpdate: (data: string | number | undefined) => void;
};

/**
 * Editor for picking one of an enumerated list
 */
export const EnumeratedEditor = ({
  name,
  values,
  optional,
  data,
  onDataUpdate
}: EnumeratedEditorProps) => (
  <OwnUpDropdown
    label={name}
    value={data}
    onChange={(event: any) => {
      const newValue = values.find((v) => event.target.value == v);
      onDataUpdate(newValue);
    }}
  >
    {optional ? (
      <OwnUpMenuItem key="!!!optional!!!" value="NULL_VALUE">
        None
      </OwnUpMenuItem>
    ) : null}
    {values.map((v) => (
      <OwnUpMenuItem key={v.toString()} value={v.toString()}>
        {v}
      </OwnUpMenuItem>
    ))}
  </OwnUpDropdown>
);

type FlagsEditorProps = {
  name: string;
  values: (string | number)[];
  data: (string | number)[] | undefined;
  optional: boolean;
  onDataUpdate: (data: (string | number)[] | undefined) => void;
};

/**
 * Editor for picking multiple of an enumerated list
 * Useful for things things like flag enums, or for using the `in` `notIn` operators.
 */
export const FlagsEditor = ({ name, values, data, onDataUpdate }: FlagsEditorProps) => (
  <OwnUpDropdown
    label={name}
    value={data ?? []}
    multiple={true}
    renderValue={(v) => {
      const fullList = (v as string[]).join(', ');
      if (fullList.length > 103) {
        return `${fullList.substr(0, 100)}...`;
      }
      return fullList;
    }}
    onChange={(event) => {
      const newValues = event.target.value as string[];
      const newValue = values.filter((v) => newValues.some((nv) => v == nv));
      onDataUpdate(newValue);
    }}
  >
    {values.map((v) => (
      <OwnUpMenuItem key={v.toString()} value={v.toString()}>
        {v}
        {(data ?? []).includes(v) ? '   ✔' : ''}
      </OwnUpMenuItem>
    ))}
  </OwnUpDropdown>
);
