import { OwnUpTooltip } from '@rategravity/own-up-component-library';
import Markdown from 'markdown-to-jsx';
import React from 'react';

export type MaybeTooltipProps = Parameters<typeof OwnUpTooltip>[0];

export const MaybeTooltip = ({ description, ...props }: MaybeTooltipProps) => {
  const Wrapper = description ? OwnUpTooltip : React.Fragment;
  if (typeof description === 'string') {
    return <Wrapper description={<Markdown>{description}</Markdown>} {...props} />;
  } else {
    return <Wrapper description={description} {...props} />;
  }
};
