import { RuleInfo } from './types';

export const rules = (budgetTypes: string[]): RuleInfo[] => [
  {
    type: 'campaign',
    params: {
      campaignName: {
        optional: false,
        type: 'string',
        description: 'Name of the outbound campaign this match will be associated with.'
      },
      score: {
        optional: false,
        type: 'number',
        description: 'The base score this match will receive before any modifiers are applied.'
      },
      leadCost: {
        optional: false,
        type: 'number',
        unit: 'money',
        description: 'The cost of a lead that matches with this rule.'
      },
      budgetType: {
        optional: false,
        type: 'enumerated',
        values: budgetTypes,
        description:
          'The type of budget this match will consume. Values depend on the budget type(s) available to the selected lender.'
      },
      isClearinghouse: {
        optional: true,
        type: 'boolean',
        description: 'Whether this campaign is a clearinghouse.'
      },
      exclusivityGroup: {
        optional: true,
        type: 'list',
        typeInfo: {
          type: 'string'
        },
        description: `For matches in an \`exclusivityGroup\`, the highest scoring match will be selected from that group and any others will be discarded.`
      }
    }
  }
];
