import React from 'react';
import styled from 'styled-components';
import { FactInfo, ParamInfo } from '../../modules/rules/schema';
import { MaybeTooltip } from '../tooltips/maybe-tooltip';
import { TypeEditor } from './type-editor';

type ParameterEditorProps = {
  factOptions: FactInfo[];
  params: Record<string, ParamInfo>;
  data: Record<string, unknown>;
  onDataUpdate: (data: Record<string, unknown>) => void;
};

const ParameterContainer = styled.div``;

/**
 * Parameter Editor to render and editor for every parameter.
 */
export const ParameterEditor = ({
  factOptions,
  params,
  data,
  onDataUpdate
}: ParameterEditorProps) => {
  return (
    <ParameterContainer>
      {Object.entries(params).map(([key, value]) => (
        <MaybeTooltip key={key} description={value.description} placement="top">
          <div>
            <TypeEditor
              name={key}
              typeInfo={value}
              optional={value.optional}
              data={data[key]}
              factOptions={factOptions}
              onDataUpdate={(d) => onDataUpdate({ ...data, [key]: d })}
            />
          </div>
        </MaybeTooltip>
      ))}
    </ParameterContainer>
  );
};
