export interface TestResult {
  name: string;
  // The test, which is really a lead with additional properties
  test: Record<string, string>;
  result: {
    lenderId: string;
    score: number;
    leadCost: number;
  }[];
}

export interface RulesValidationState {
  running: boolean;
  results: TestResult[];
}

export const initialState: RulesValidationState = {
  running: false,
  results: []
};
