import { useInternalAuth } from '@rategravity/widgets-react/modules/auth/internal/hooks/use-internal-auth';
import React, { useEffect } from 'react';
import { AuthHeader } from '../../components/auth-header';
import { UnauthHeader } from '../../components/unauth-header';

export const Auth = () => {
  const { isLoggedIn, login } = useInternalAuth();
  useEffect(() => {
    if (!isLoggedIn) {
      void login(window.location.origin);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get('redirect');
      // Redirect to the path that the user was trying to access
      window.location.assign(redirect || '/');
    }
  }, [isLoggedIn, login]);
  return <AuthHeader />;
};

export const Unauthorized = () => {
  // Default redirect path
  let redirectPath = '/';
  // Try to get the redirect path from the referrer
  if (document.referrer) {
    try {
      redirectPath = new URL(document.referrer).pathname;
    } catch {
      // ... if we couldn't generate the redirectPath, just use the default.
    }
  }
  const { login } = useInternalAuth();
  // Try logging the user in again
  useEffect(() => {
    void login(window.location.origin + '/auth?redirect=' + redirectPath);
  }, [login, redirectPath]);

  return <UnauthHeader login={login} redirectPath={`?redirect=${redirectPath}`} />;
};
