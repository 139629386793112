import { LenderBudget } from './types';

export interface LenderBudgetConfigState {
  loading: boolean;
  error: boolean;
  allLenderIds: string[];
  /** Outer record key is lenderId, inner record key is budgetType */
  budgets: Record<string, Record<string, LenderBudget>>;
  enabledLenders: string[];
}

export const initialState: LenderBudgetConfigState = {
  loading: true,
  error: false,
  allLenderIds: [],
  budgets: {},
  enabledLenders: []
};
