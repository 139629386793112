import { TestResult } from './state';

export const START_RULES_VALIDATION_TYPE = 'START_RULES_VALIDATIOIN' as const;
export const startRulesValidation = () => ({
  type: START_RULES_VALIDATION_TYPE
});
export type StartRulesValidationAction = ReturnType<typeof startRulesValidation>;

export const RULES_VALIDATION_FINISHED_TYPE = 'RULES_VALIDATION_FINISHED' as const;
export const rulesValidationFinished = (results: TestResult[]) => ({
  type: RULES_VALIDATION_FINISHED_TYPE,
  payload: results
});
export type RulesValidationFinishedAction = ReturnType<typeof rulesValidationFinished>;

export const RULES_VALIDATION_FAILED_TYPE = 'RULES_VALIDATION_FAILED' as const;
export const rulesValidationFailed = (error: string) => ({
  type: RULES_VALIDATION_FAILED_TYPE,
  payload: error
});
export type RulesValidationFailedAction = ReturnType<typeof rulesValidationFailed>;
