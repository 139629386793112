import { all, call, put, takeEvery } from 'redux-saga/effects';

import { runRulesValidation } from '../../modules/api/rules-validation';
import {
  rulesValidationFailed,
  rulesValidationFinished,
  START_RULES_VALIDATION_TYPE,
  StartRulesValidationAction
} from './actions';
import { TestResult } from './state';

function* startRulesValidation(_: StartRulesValidationAction) {
  try {
    const results: TestResult[] = yield call(runRulesValidation);
    yield put(rulesValidationFinished(results));
  } catch (err) {
    console.log('Error running rules validation: ', err);
    yield put(rulesValidationFailed(err as string));
  }
}

export function* rulesValidationSaga() {
  yield all([takeEvery([START_RULES_VALIDATION_TYPE], startRulesValidation)]);
}
