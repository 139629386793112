import { LenderBudget } from './types';

export const LOAD_LENDER_CONFIG_ACTION_TYPE = 'LOAD_LENDER_CONFIG' as const;
export const loadLenderConfigAction = () => ({
  type: LOAD_LENDER_CONFIG_ACTION_TYPE
});
export type LoadLenderConfigAction = ReturnType<typeof loadLenderConfigAction>;

export const LOAD_BUDGETS_ACTION_TYPE = 'LOAD_BUDGETS' as const;
export const loadBudgetsAction = () => ({
  type: LOAD_BUDGETS_ACTION_TYPE
});
export type LoadBudgetsAction = ReturnType<typeof loadBudgetsAction>;

export const LENDER_BUDGETS_LOADED_ACTION_TYPE = 'LENDER_BUDGETS_LOADED' as const;
export const lenderBudgetsLoadedAction = (budgets: LenderBudget[]) => ({
  type: LENDER_BUDGETS_LOADED_ACTION_TYPE,
  payload: {
    budgets
  }
});
export type LenderBudgetsLoadedAction = ReturnType<typeof lenderBudgetsLoadedAction>;

export const LOAD_LENDER_CONFIG_FAILED_ACTION_TYPE = 'LOAD_LENDER_FAILED_CONFIG' as const;
export const loadLenderConfigFailedAction = () => ({
  type: LOAD_LENDER_CONFIG_FAILED_ACTION_TYPE
});
export type LoadLenderConfigFailedAction = ReturnType<typeof loadLenderConfigFailedAction>;

export const UPDATE_LENDER_BUDGET_ACTION_TYPE = 'UPDATE_LENDER_BUDGET' as const;
export const updateLenderBudgetAction = (budget: LenderBudget) => ({
  type: UPDATE_LENDER_BUDGET_ACTION_TYPE,
  payload: {
    budget
  }
});
export type UpdateLenderBudgetAction = ReturnType<typeof updateLenderBudgetAction>;

export const LENDER_BUDGET_UPDATED_ACTION_TYPE = 'LENDER_BUDGET_UPDATED' as const;
export const lenderBudgetUpdatedAction = (budget: LenderBudget) => ({
  type: LENDER_BUDGET_UPDATED_ACTION_TYPE,
  payload: {
    budget: {
      ...budget,
      // Make sure new budget types are locked after they are submitted
      allowEditType: undefined
    }
  }
});
export type LenderBudgetUpdatedAction = ReturnType<typeof lenderBudgetUpdatedAction>;

export const ENABLED_LENDERS_LOADED_ACTION_TYPE = 'ENABLED_LENDERS_LOADED' as const;
export const enabledLendersLoadedAction = (enabledLenders: string[]) => ({
  type: ENABLED_LENDERS_LOADED_ACTION_TYPE,
  payload: {
    enabledLenders
  }
});
export type EnabledLendersLoadedAction = ReturnType<typeof enabledLendersLoadedAction>;

export const UPDATE_LENDER_ENABLED_ACTION_TYPE = 'UPDATE_LENDER_ENABLED' as const;
export const updateLenderEnabledAction = (lenderId: string, enabled: boolean) => ({
  type: UPDATE_LENDER_ENABLED_ACTION_TYPE,
  payload: {
    lenderId,
    enabled
  }
});
export type UpdateLenderEnabledAction = ReturnType<typeof updateLenderEnabledAction>;
