import { OwnUpIconButton, OwnUpIconButtonQuiet } from '@rategravity/own-up-component-library';
import { ChevronDownIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-down';
import { ChevronRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-right';
import { MinusIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/minus';
import { NestedCondition } from 'json-rules-engine';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FactInfo } from '../../modules/rules/schema';
import { ComparisonEditor } from './comparison-editor';

const InsetContainer = styled.div`
  display: grid;
  grid-column: span 5;
  /** 225px is big enough so the longest comparison operator does not get cutoff */
  grid-template-columns: 48px 3fr 225px 3fr 48px;
  border-left: thin solid black;
  padding-left: 48px;

  /** MUI has an extra large padding-right so text doesn't overlap dropdowns  */
  /** slimming that down so options don't get cutoff */
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-right: 30px;
  }
  .MuiSelect-root:not(.MuiInputBase-inputAdornedEnd) {
    padding-right: 30px;
  }
`;

const RemoveButtonContainer = styled.div`
  grid-column: span 3;
`;

type BooleanEditorProps = {
  path: string;
  canCollapse: boolean;
  facts: FactInfo[];
  validationErrors: Map<string, string>;
  conditionSelect: React.ReactNode;
  removeButton: React.ReactNode;
  condition: { all: NestedCondition[] } | { any: NestedCondition[] };
  conditions: string[];
  onConditionUpdate: (comparison: { all: NestedCondition[] } | { any: NestedCondition[] }) => void;
};

/**
 * Editor for editing the children of an all or any
 * Each child renders it's own comparison editor.
 * An empty comparison editor is used to add new children
 */
export const BooleanEditor = ({
  path,
  canCollapse,
  facts,
  validationErrors,
  conditionSelect,
  removeButton,
  condition,
  conditions,
  onConditionUpdate
}: BooleanEditorProps) => {
  const subConditions = ('all' in condition ? condition.all : condition.any) ?? [];
  const [expanded, setExpanded] = useState(true);
  return (
    <React.Fragment>
      {canCollapse ? (
        <OwnUpIconButtonQuiet
          variant="text"
          name={expanded ? 'Collapse' : 'Expand'}
          onClick={() => setExpanded((e) => !e)}
          icon={expanded ? ChevronDownIcon : ChevronRightIcon}
        />
      ) : null}
      {conditionSelect}
      <RemoveButtonContainer>{removeButton}</RemoveButtonContainer>
      {expanded ? (
        <InsetContainer>
          {subConditions.map((c, i) => (
            <ComparisonEditor
              key={i}
              path={`${path}/${i}`}
              condition={c}
              facts={facts}
              conditions={conditions}
              validationErrors={validationErrors}
              onConditionUpdate={(update) => {
                const n = subConditions.map((sc, sci) => (sci === i ? update : sc));
                if ('all' in condition) {
                  onConditionUpdate({ all: n });
                } else {
                  onConditionUpdate({ any: n });
                }
              }}
              removeButton={
                <OwnUpIconButton
                  name="Remove"
                  icon={MinusIcon}
                  onClick={() => {
                    const n = subConditions.filter((_, sci) => sci !== i);
                    if ('all' in condition) {
                      onConditionUpdate({ all: n });
                    } else {
                      onConditionUpdate({ any: n });
                    }
                  }}
                />
              }
            />
          ))}
          <ComparisonEditor
            key={subConditions.length}
            path={path}
            condition={undefined}
            facts={facts}
            conditions={conditions}
            validationErrors={validationErrors}
            onConditionUpdate={(c) => {
              const n = [...subConditions, c];
              if ('all' in condition) {
                onConditionUpdate({ all: n });
              } else {
                onConditionUpdate({ any: n });
              }
            }}
            removeButton={null}
          />
        </InsetContainer>
      ) : null}
    </React.Fragment>
  );
};
