import { OwnUpComponentLibraryProvider, OwnUpSpinner } from '@rategravity/own-up-component-library';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { DEFAULT_LENDER_ID } from '../../modules/rules/schema/constants';
import { loadLenderRulesAction } from '../../redux/lender-rules/actions';
import { lenderRulesLoadingSelector } from '../../redux/lender-rules/selector';
import { AppState } from '../../redux/store';
import { LenderConfiguration } from '.';
import { LenderConditionEditorPage } from './lender-condition-editor';
import { LenderRuleEditorPage } from './lender-rule-editor';
import { LenderRulesEditorPage } from './lender-rules-editor';

const LenderSpecificConfiguration = ({
  basePath,
  lenderId
}: {
  basePath: string;
  lenderId: string;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadLenderRulesAction(lenderId));
    if (lenderId !== DEFAULT_LENDER_ID) {
      dispatch(loadLenderRulesAction(DEFAULT_LENDER_ID));
    }
  }, [dispatch, lenderId]);
  const history = useHistory();
  const loadingSelector = useCallback(
    (state: AppState) => lenderRulesLoadingSelector(state, { lenderId }),
    [lenderId]
  );
  const lenderLoading = useSelector(loadingSelector);
  const defaultLoading = useSelector((state: AppState) =>
    lenderRulesLoadingSelector(state, { lenderId: DEFAULT_LENDER_ID })
  );
  if (lenderLoading || defaultLoading) {
    return <OwnUpSpinner />;
  }
  return (
    <Switch>
      <Route
        path={`${basePath}/editor/condition/:conditionId`}
        render={({ match }) => (
          <LenderConditionEditorPage
            lenderId={lenderId}
            conditionId={match.params.conditionId}
            onDone={() => {
              history.push(`${basePath}`);
            }}
          />
        )}
      />
      <Route
        path={`${basePath}/editor/rule/:ruleId`}
        render={({ match }) => (
          <LenderRuleEditorPage
            lenderId={lenderId}
            ruleId={match.params.ruleId}
            onDone={() => {
              history.push(`${basePath}`);
            }}
          />
        )}
      />
      <Route
        path={`${basePath}`}
        render={() => (
          <LenderRulesEditorPage
            lenderId={lenderId}
            onConditionEdit={(conditionId) =>
              history.push(`${basePath}/editor/condition/${conditionId}`)
            }
            onRuleEdit={(ruleId) => history.push(`${basePath}/editor/rule/${ruleId}`)}
          />
        )}
      />
    </Switch>
  );
};

export const LenderConfigurationApp = () => {
  const routeMatch = useRouteMatch();
  const basePath = routeMatch.path;
  return (
    <OwnUpComponentLibraryProvider>
      <Switch>
        <Route
          path={`${basePath}/:lenderId`}
          render={({ match }) => (
            <LenderSpecificConfiguration
              basePath={`${basePath}/${match.params.lenderId}`}
              lenderId={match.params.lenderId}
            />
          )}
        />
        <Route path={basePath}>
          <LenderConfiguration />
        </Route>
      </Switch>
    </OwnUpComponentLibraryProvider>
  );
};
