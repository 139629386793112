import { USStateAndTerritoriesType, USStatesAndTerritories } from '@rategravity/core-lib/enums';
import { createSelector } from 'reselect';
import * as t from 'type-shift';
import { LenderColumnState, LenderRowInfo } from '../../components/lender-table/properties';
import { parseConstraints } from '../../modules/parse-constraints';
import { Lender } from '../store/lender/types';
import { activeStateSelector, isShowingUnlicensedStates, lendersSelector } from '.';

/**
 * Select the headers for the lender table (LENDERS followed by active states)
 */
export const stateHeaderSelector = createSelector(
  activeStateSelector,
  isShowingUnlicensedStates,
  (activeStates: USStateAndTerritoriesType[], showingUnlicensed) => [
    'LENDERS',
    ...activeStates,
    ...(showingUnlicensed
      ? ['', ...USStatesAndTerritories.filter((state) => !activeStates.includes(state))]
      : [])
  ]
);

export const getLenderColumnState = (lender: Lender, state: string) => {
  if (state === '') {
    return LenderColumnState.DIVIDER;
  }

  const validatedState: USStateAndTerritoriesType = t.oneOf(USStatesAndTerritories)(state);

  if (!lender.states.licensedStates?.includes(validatedState)) {
    return LenderColumnState.UNLICENSED;
  }
  if (!lender.states.enabledStates?.includes(validatedState)) {
    return LenderColumnState.DISABLED;
  }
  return parseConstraints(lender.states.autoQuoteConstraints, validatedState, lender.autoQuotable);
};

/**
 * Return a key/value mapping of lender names and their status
 *   in each state (will be in the same order as `stateHeadersSelector`
 *    output.)
 */
export const lenderRowSelector = createSelector(
  stateHeaderSelector,
  lendersSelector,
  (headers: string[], lenders: Record<string, Lender>) =>
    Object.entries(lenders).reduce(
      (acc, [key, lender]) => ({
        ...acc,
        [key]: {
          name: lender.name,
          id: lender.id,
          autoQuotable: lender.autoQuotable || false,
          stateSettings: (headers.slice(1) as USStateAndTerritoriesType[]).map(
            (state): LenderColumnState => getLenderColumnState(lender, state)
          )
        }
      }),
      {} as Record<string, LenderRowInfo>
    )
);
