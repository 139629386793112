import { faker } from '@faker-js/faker';
import { OwnUpSpinner } from '@rategravity/own-up-component-library';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import React from 'react';
import { schema } from './schema';
import { SentLead, useEvaluateLead } from './use-evaluate-lead';

const THIRTY_DAYS = 1000 * 60 * 60 * 24 * 30;

const createLeadPayload = () => ({
  // -- Lead tracking information
  /* Unique Id for this lead */
  leadID: faker.datatype.uuid(),
  creditRating: faker.helpers.arrayElement(['Excellent', 'Good', 'Fair', 'Poor']),
  vaEligible: false,

  // -- Property information
  propertyCity: faker.address.city(),
  propertyState: 'MA',
  propertyCounty: faker.address.county(),
  propertyZip: faker.address.zipCode(),
  /** How much the property is worth */
  propertyValue: 800000,
  /** Type of property in question */
  propertyType: faker.helpers.arrayElement([
    'SingleFamily' as const,
    'MultiFamily' as const,
    'Condo' as const,
    'Manufactured' as const,
    'Townhome' as const,
    'NewConstruction' as const
  ]),
  /** How the property is being used */
  propertyUse: faker.helpers.arrayElement([
    'Primary' as const,
    'Secondary' as const,
    'Investment' as const
  ]),
  // -- Overwriting engine facts
  hourOfDay: faker.datatype.number({ min: 0, max: 23 }),
  dayOfTheWeek: faker.datatype.number({ min: 0, max: 6 }),
  // -- Loan information
  /** Type of transaction */
  loanPurpose: faker.helpers.arrayElement(['Purchase' as const, 'Refinance' as const]),
  /** Size of the loan */
  loanAmount: 300000,

  // -- Universal Lead identification
  jornayaLeadId: faker.datatype.uuid(),

  // -- Internal Lead Payload fields
  source: 'Own Up',
  employmentStatus: faker.helpers.arrayElement(['Employed', 'SelfEmployed', 'Retired']),
  incomeType: faker.helpers.arrayElement([
    'Salary',
    'Hourly',
    'Bonus',
    'Commission',
    'Pension',
    'SelfEmployed',
    'NoIncome',
    'Other'
  ]),
  closeDate: Date.now() + THIRTY_DAYS,
  purchaseTimeline: faker.datatype.number({ min: 0, max: 7 })
});

const ResultDisplay = ({ sent }: { sent: SentLead }) => {
  switch (sent.status) {
    case 'Sending':
      return <OwnUpSpinner />;
    case 'Success':
    case 'Failed':
      return (
        <div>
          <h2>{sent.status}</h2>
          <h3>Request</h3>
          <pre>{JSON.stringify(sent.request, undefined, 2)}</pre>
          <h3>Response</h3>
          <pre>{JSON.stringify(sent.response, undefined, 2)}</pre>
        </div>
      );
    default:
      return null;
  }
};

export const EvaluateLeadPage = () => {
  const [data, setData] = React.useState(createLeadPayload);
  const [result, send] = useEvaluateLead();
  return (
    <React.Fragment>
      <Form
        validator={validator}
        schema={schema}
        formData={data}
        onChange={(e) => setData(e.formData)}
        onSubmit={(e) => send(e.formData!)}
      />
      <ResultDisplay sent={result} />
    </React.Fragment>
  );
};
