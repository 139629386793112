import { OwnUpDropdown, OwnUpMenuItem } from '@rategravity/own-up-component-library';
import React from 'react';

type BooleanEditorProps = {
  name: string;
  data: boolean | undefined;
  onDataUpdate: (data: boolean) => void;
};

/**
 * Editor for boolean type, not just a checkbox.
 */
export const BooleanEditor = ({ name, data, onDataUpdate }: BooleanEditorProps) => {
  let value = undefined;
  if (data === true) {
    value = 'yes';
  } else if (data === false) {
    value = 'no';
  }
  return (
    <OwnUpDropdown
      label={name}
      value={value}
      onChange={(event) => {
        onDataUpdate(event.target.value === 'yes');
      }}
    >
      <OwnUpMenuItem value="yes">Yes</OwnUpMenuItem>
      <OwnUpMenuItem value="no">No</OwnUpMenuItem>
    </OwnUpDropdown>
  );
};
