import { OwnUpDropdown, OwnUpMenuItem } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { ErrorText } from './error-text';

type ConditionReferenceEditorProps = {
  path: string;
  validationErrors: Map<string, string>;
  conditionSelect: React.ReactNode;
  removeButton: React.ReactNode;
  condition: { not: { condition: string } } | { condition: string };
  onConditionUpdate: (condition: { not: { condition: string } } | { condition: string }) => void;
};

const SelectContainer = styled.div`
  grid-column: span 3;
`;

const RemoveButtonContainer = styled.div`
  grid-column: 5;
`;

/**
 * Condition reference editor they can either by { condition: 'name' }
 * or { not: { condition: 'name' } }
 */
export const ConditionReferenceEditor = ({
  path,
  validationErrors,
  conditionSelect,
  removeButton,
  condition,
  onConditionUpdate
}: ConditionReferenceEditorProps) => {
  const conditionName = 'not' in condition ? condition.not.condition : condition.condition;
  const error = validationErrors.get(path) ?? validationErrors.get(`${path}/not`);
  return (
    <React.Fragment>
      <SelectContainer>{conditionSelect}</SelectContainer>
      <OwnUpDropdown
        value={'not' in condition! ? 'false' : 'true'}
        onChange={(event) => {
          if (event.target.value === 'true') {
            onConditionUpdate({ condition: conditionName });
          } else {
            onConditionUpdate({ not: { condition: conditionName } });
          }
        }}
      >
        <OwnUpMenuItem value="true">True</OwnUpMenuItem>
        <OwnUpMenuItem value="false">False</OwnUpMenuItem>
      </OwnUpDropdown>
      <RemoveButtonContainer>{removeButton}</RemoveButtonContainer>
      <ErrorText error={error} />
    </React.Fragment>
  );
};
