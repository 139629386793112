import type { RuleProperties, TopLevelCondition } from 'json-rules-engine';
export interface LenderCondition {
  status: 'clean' | 'edited' | 'deleted';
  name: string;
  conditions: TopLevelCondition;
}

export interface LenderRule extends Omit<RuleProperties, 'onSuccess' | 'onFailure'> {
  status: 'clean' | 'edited' | 'deleted';
  name: string;
  ruleSets: string[];
  enabled: boolean;
}

export interface LenderState {
  loading: boolean;
  error: boolean;
  savingRules: boolean;
  rules: Record<string, LenderRule>;
  conditions: Record<string, LenderCondition>;
}

export type LenderRulesState = Record<string, LenderState>;

export const initialState: LenderState = {
  loading: true,
  error: false,
  savingRules: false,
  rules: {},
  conditions: {}
};
