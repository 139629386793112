import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import { LenderColumnState } from '../../components/lender-table/properties';
import { Lender } from '../store/lender/types';

export const CREATE_LENDERS = 'CREATE_LENDER';
export interface CreateLendersAction {
  type: typeof CREATE_LENDERS;
  payload: Lender[];
}

export const SELECT_ACTIVE_CONFIG = 'SELECT_CONFIG';
export interface SelectActiveConfigAction {
  type: typeof SELECT_ACTIVE_CONFIG;
  payload: string;
}
export const FETCH_ALL_LENDERS = 'FETCH_ALL_LENDERS';
export interface FetchAllLendersAction {
  type: typeof FETCH_ALL_LENDERS;
}
export const BEGIN_LOADING = 'BEGIN_LOADING';
export interface BeginLoadingAction {
  type: typeof BEGIN_LOADING;
  payload: {
    slice: 'lenders' | 'activeStates';
  };
}
export const END_LOADING = 'END_LOADING';
export interface EndLoadingAction {
  type: typeof END_LOADING;
  payload: {
    slice: 'lenders' | 'activeStates';
  };
}

export const UPDATE_LENDER = 'UPDATE_LENDER';
export interface UpdateLenderAction {
  type: typeof UPDATE_LENDER;
  payload: {
    id: string;
    state: USStateAndTerritoriesType;
    setting: LenderColumnState;
  };
}

export const BEGIN_SAVING = 'BEGIN_SAVING';
export interface BeginSavingAction {
  type: typeof BEGIN_SAVING;
}

export const END_SAVING = 'END_SAVING';
export interface EndSavingAction {
  type: typeof END_SAVING;
}

export const SAVE_LENDER = 'SAVE_LENDER';
export interface SaveLenderAction {
  type: typeof SAVE_LENDER;
  payload: { lender: Lender; reason: string };
}

export const SAVE_LENDERS = 'SAVE_LENDERS';
export interface SaveLendersAction {
  type: typeof SAVE_LENDERS;
  payload: { reason: string };
}

export const SET_ERROR = 'SET_ERROR';
export interface SetErrorAction {
  type: typeof SET_ERROR;
}

export const SET_LAST_MODIFIED_TIME = 'SET_LAST_MODIFIED_TIME';
export interface SetLastModifiedTimeAction {
  type: typeof SET_LAST_MODIFIED_TIME;
  payload: { lastModifiedTime: number | null };
}

export const SYNC_PROD_DATA = 'SYNC_PROD_DATA';
export interface SyncProdDataAction {
  type: typeof SYNC_PROD_DATA;
}

export const FETCH_LICENSED_STATES = 'FETCH_LICENSED_STATES';
export interface FetchLicensedStates {
  type: typeof FETCH_LICENSED_STATES;
}

export const LOADED_LICENSED_STATES = 'LOADED_LICENSED_STATES';
export interface LoadedLicensedStates {
  type: typeof LOADED_LICENSED_STATES;
  payload: {
    licensedStates: string[];
  };
}
