import { OwnUpDropdown, OwnUpMenuItem } from '@rategravity/own-up-component-library';
import { RuleProperties } from 'json-rules-engine';
import React from 'react';
import { FactInfo, RuleInfo } from '../../modules/rules/schema';
import { ParameterEditor } from '../parameter-editor';

type EventEditorProps = {
  factOptions: FactInfo[];
  ruleOptions: RuleInfo[];
  event: RuleProperties['event'] | undefined;
  onEventUpdate: (event: RuleProperties['event']) => void;
};

export const EventEditor = ({
  event,
  factOptions,
  ruleOptions,
  onEventUpdate
}: EventEditorProps) => {
  const eventTypes = ruleOptions.map((rule) => rule.type);
  const ruleInfo = ruleOptions.find((rule) => rule.type === event?.type);
  return (
    <div>
      <OwnUpDropdown
        value={event?.type}
        label="Event type"
        onChange={(evt) => onEventUpdate({ type: `${evt.target.value}` })}
      >
        {eventTypes.map((type) => (
          <OwnUpMenuItem key={type} value={type}>
            {type}
          </OwnUpMenuItem>
        ))}
      </OwnUpDropdown>
      {ruleInfo && ruleInfo.params && (
        <ParameterEditor
          factOptions={factOptions}
          params={ruleInfo?.params}
          data={event?.params ?? {}}
          onDataUpdate={(params) => {
            onEventUpdate({ ...(event as RuleProperties['event']), params });
          }}
        />
      )}
    </div>
  );
};
