import { OwnUpHeader } from '@rategravity/own-up-component-library-legacy';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchAllLendersAction, fetchLicensedStates } from '../../redux/actions';
import { hasErrorSelector, isLoadingSelector } from '../../redux/selectors';
import { LenderTable } from '../lender-table/component';
import { SyncButton } from './sync-button';

export const LenderStateConfigData = () => {
  const loading = useSelector(isLoadingSelector);
  const error = useSelector(hasErrorSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLicensedStates());
    dispatch(fetchAllLendersAction());
  }, [dispatch]);

  if (loading) {
    return <OwnUpHeader>Loading...</OwnUpHeader>;
  }
  if (error) {
    return <Redirect to="/unauthorized" />;
  }
  return (
    <React.Fragment>
      <LenderTable title={'State Configuration Table'} />
      {process.env.DEPLOY_ENV === 'staging' ? <SyncButton /> : null}
    </React.Fragment>
  );
};
