import { ConditionReference } from 'json-rules-engine';
import React from 'react';
import { ConditionReferenceNode } from './condition-reference-node';

interface NotConditionsNodeProps {
  data: ConditionReference;
}

export const NotConditionsNode: React.FC<NotConditionsNodeProps> = ({ data }) => {
  return (
    <>
      Not <ConditionReferenceNode data={data} />
    </>
  );
};
