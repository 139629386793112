import { ConditionProperties } from 'json-rules-engine';
import React from 'react';
import { formatConditionProps } from '../../modules/rules/format-rules';
interface ConditionPropertiesNodeProps {
  data: ConditionProperties;
}

export const ConditionPropertiesNode: React.FC<ConditionPropertiesNodeProps> = ({ data }) => {
  return <React.Fragment>{formatConditionProps(data)}</React.Fragment>;
};
