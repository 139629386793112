import {
  OwnUpCheckbox,
  OwnUpFillButtonPrimary,
  OwnUpFillButtonSecondary
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { ParamInfo, TypeInfo } from '../../modules/rules/schema';
import { ParameterEditor } from '.';
import { TypeEditor } from './type-editor';

type ObjectEditorProps = {
  name: string;
  properties: Record<string, ParamInfo>;
  optional: boolean;
  data: Record<string, unknown> | undefined;
  onDataUpdate: (data: Record<string, unknown> | undefined) => void;
};

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ParameterContainerInset = styled.div`
  border-left: thin solid black;
  padding-left: 20px;
`;

/**
 * Editor for an object value
 * Renders editors for all the properties of the object
 * the "factOptions" are passed as [] because JSON rules engine doesn't allow nested fact replacement.
 */
export const ObjectEditor = ({
  name,
  optional,
  properties,
  data,
  onDataUpdate
}: ObjectEditorProps) => (
  <ParameterContainerInset>
    <NameContainer>
      {name}
      {optional ? (
        <OwnUpCheckbox
          value={data !== undefined}
          onChange={(e) => {
            if (e.target.checked) {
              onDataUpdate({});
            } else {
              onDataUpdate(undefined);
            }
          }}
        >
          Include
        </OwnUpCheckbox>
      ) : null}
    </NameContainer>
    {optional && data === undefined ? null : (
      <ParameterEditor
        factOptions={[]}
        params={properties}
        data={data ?? {}}
        onDataUpdate={onDataUpdate}
      />
    )}
  </ParameterContainerInset>
);

type ListEditorProps = {
  name: string;
  typeInfo: TypeInfo;
  optional: boolean;
  data: unknown[] | undefined;
  onDataUpdate: (data: unknown[] | undefined) => void;
};

/**
 * Editor for a List
 * allows adding / removing values and renders
 * the editor appropriate for the value type.
 */
export const ListEditor = ({ name, optional, typeInfo, data, onDataUpdate }: ListEditorProps) => (
  <ParameterContainerInset>
    <NameContainer>
      {name}
      {optional ? (
        <OwnUpCheckbox
          value={data !== undefined}
          onChange={(e) => {
            if (e.target.checked) {
              onDataUpdate([]);
            } else {
              onDataUpdate(undefined);
            }
          }}
        >
          Include
        </OwnUpCheckbox>
      ) : null}
    </NameContainer>
    {optional && data === undefined ? null : (
      <React.Fragment>
        {(data ?? []).map((d, i, list) => (
          <NameContainer key={i}>
            <TypeEditor
              name={`Item ${i + 1}`}
              typeInfo={typeInfo}
              optional={false}
              factOptions={[]}
              data={d}
              onDataUpdate={(v) => onDataUpdate(list.map((dv, di) => (di === i ? v : dv)))}
            />
            <OwnUpFillButtonSecondary
              onClick={() => {
                onDataUpdate(list.filter((_, di) => di !== i));
              }}
            >
              {' '}
              Remove{' '}
            </OwnUpFillButtonSecondary>
          </NameContainer>
        ))}
        <OwnUpFillButtonPrimary
          onClick={() => {
            onDataUpdate([...(data ?? []), null]);
          }}
        >
          {' '}
          +Add{' '}
        </OwnUpFillButtonPrimary>
      </React.Fragment>
    )}
  </ParameterContainerInset>
);
