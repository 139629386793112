import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import { AutoQuoteConstraints } from '../redux/store/lender/types';

/**
 * Takes an exitsting set of constraints and updates them.
 *
 * @param constraints constraints to be updated
 * @param state US state being updated
 * @param toUpdate optional constraints to add
 */

export const updateConstraints = (
  constraints: AutoQuoteConstraints[],
  state: USStateAndTerritoriesType,
  toUpdate?: AutoQuoteConstraints
) => {
  // remove all constraints containing the state
  const resetConstraints = constraints.filter(([c]) => c.state !== state);
  return toUpdate ? [...resetConstraints, toUpdate] : resetConstraints;
};
