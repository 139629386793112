import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  deleteLenderRulesApi,
  fetchLenderConditionsApi,
  LenderRuleResult,
  updateLenderRulesApi,
  UpdateLenderRulesPayload
} from '../../modules/api/lender-rules';
import { startRulesValidation } from '../rules-validation/actions';
import {
  lenderRulesLoadedAction,
  LOAD_LENDER_RULES_ACTION_TYPE,
  LoadLenderRulesAction,
  loadLenderRulesFailedAction,
  SAVE_LENDER_RULES_ACTION_TYPE,
  SAVED_LENDER_RULES_ACTION_TYPE,
  SavedLenderRulesAction,
  savedLenderRulesAction,
  SaveLenderRulesAction
} from './actions';
import { deletePayloadSelector, updatePayloadSelector } from './selector';

function* fetchLenderRules({ payload: { lenderId } }: LoadLenderRulesAction) {
  try {
    const result: LenderRuleResult = yield call(fetchLenderConditionsApi, lenderId);
    yield put(lenderRulesLoadedAction(lenderId, result));
  } catch (err) {
    yield put(loadLenderRulesFailedAction(lenderId));
  }
}

function* saveLenderRules({ payload }: SaveLenderRulesAction) {
  try {
    const update: UpdateLenderRulesPayload = yield select(updatePayloadSelector, payload);
    const deletePayload: ReturnType<typeof deletePayloadSelector> = yield select(
      deletePayloadSelector,
      payload
    );
    yield all([call(updateLenderRulesApi, update), call(deleteLenderRulesApi, deletePayload)]);
    yield put(savedLenderRulesAction(payload.lenderId));
  } catch (err) {
    console.error('Error updating rules', err);
  }
}

function* handleLenderRulesSaved(_: SavedLenderRulesAction) {
  yield put(startRulesValidation());
}

export function* lenderRulesSaga() {
  yield all([takeEvery([LOAD_LENDER_RULES_ACTION_TYPE], fetchLenderRules)]);
  yield all([takeEvery([SAVE_LENDER_RULES_ACTION_TYPE], saveLenderRules)]);
  yield all([takeEvery([SAVED_LENDER_RULES_ACTION_TYPE], handleLenderRulesSaved)]);
}
