import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchAllLenderBudgetsApi,
  fetchEnabledLendersApi,
  updateLenderBudgetApi,
  updateLenderEnabledApi
} from '../../modules/api/lender-budget';
import {
  enabledLendersLoadedAction,
  lenderBudgetsLoadedAction,
  lenderBudgetUpdatedAction,
  LOAD_BUDGETS_ACTION_TYPE,
  LOAD_LENDER_CONFIG_ACTION_TYPE,
  loadLenderConfigFailedAction,
  UPDATE_LENDER_BUDGET_ACTION_TYPE,
  UPDATE_LENDER_ENABLED_ACTION_TYPE,
  UpdateLenderBudgetAction,
  UpdateLenderEnabledAction
} from './actions';
import { LenderBudget } from './types';

export function* fetchLenderBudgets() {
  try {
    const result: LenderBudget[] = yield call(fetchAllLenderBudgetsApi);
    yield put(lenderBudgetsLoadedAction(result));
  } catch (err) {
    yield put(loadLenderConfigFailedAction());
  }
}

export function* updateLenderBudget({ payload: { budget } }: UpdateLenderBudgetAction) {
  try {
    // Note: using the input to update the state to avoid delay.
    // This is a shortcut unless we decide to add a lot of error handling.
    yield put(lenderBudgetUpdatedAction(budget));
    yield call(updateLenderBudgetApi, budget);
  } catch (err) {}
}

export function* fetchEnabledLenders() {
  try {
    const result: string[] = yield call(fetchEnabledLendersApi);
    yield put(enabledLendersLoadedAction(result));
  } catch (err) {}
}

export function* updateLenderEnabled({
  payload: { lenderId, enabled }
}: UpdateLenderEnabledAction) {
  try {
    yield call(updateLenderEnabledApi, lenderId, enabled);
  } catch (err) {}
}

export function* lenderBudgetsSaga() {
  yield all([takeEvery([LOAD_LENDER_CONFIG_ACTION_TYPE], fetchLenderBudgets)]);
  yield all([takeEvery([LOAD_BUDGETS_ACTION_TYPE], fetchLenderBudgets)]);
  yield all([takeEvery([LOAD_LENDER_CONFIG_ACTION_TYPE], fetchEnabledLenders)]);
  yield all([takeEvery([UPDATE_LENDER_BUDGET_ACTION_TYPE], updateLenderBudget)]);
  yield all([takeEvery([UPDATE_LENDER_ENABLED_ACTION_TYPE], updateLenderEnabled)]);
}
