import { LOADED_LICENSED_STATES, LoadedLicensedStates } from '../actions/action-types';

export const activeStatesReducer = (
  state: string[] = [],
  action: LoadedLicensedStates
): string[] => {
  switch (action.type) {
    case LOADED_LICENSED_STATES:
      return action.payload.licensedStates;
    default:
      return state;
  }
};
