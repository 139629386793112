import { OwnUpDropdown, OwnUpMenuItem } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { camelCaseToStartCase } from '../../modules/rules/format-rules';
import { FactInfo, TypeInfo } from '../../modules/rules/schema';
import { MaybeTooltip } from '../tooltips/maybe-tooltip';
import { ParameterEditor } from '.';
import { BooleanEditor } from './boolean-editor';
import { DateEditor } from './date-editor';
import { EnumeratedEditor, FlagsEditor } from './enumerated-editor';
import { NumberEditor } from './number-editor';
import { ListEditor, ObjectEditor } from './object-editor';
import { StringEditor } from './string-editor';

type TypeEditorProps = {
  name: string;
  typeInfo: TypeInfo;
  factOptions: FactInfo[];
  optional: boolean;
  data: unknown;
  onDataUpdate: (data: unknown) => void;
};

const areEquivalent = (typeInfoA: TypeInfo, typeInfoB: TypeInfo): boolean => {
  switch (typeInfoA.type) {
    case 'string':
    case 'number':
      return typeInfoA.type === typeInfoB.type && typeInfoA.unit === typeInfoB.unit;
    case 'enumerated':
      return (
        typeInfoB.type === 'enumerated' &&
        typeInfoB.values.every((v) => typeInfoA.values.includes(v))
      );
    case 'list':
      return typeInfoB.type === 'list' && areEquivalent(typeInfoA.typeInfo, typeInfoB.typeInfo);
    case 'object':
      return (
        typeInfoB.type === 'object' &&
        Object.keys(typeInfoA.properties).length === Object.keys(typeInfoB.properties).length &&
        Object.entries(typeInfoA.properties).every(
          ([key, { optional, ...typeInfo }]) =>
            key in typeInfoB.properties &&
            typeInfoB.properties[key].optional === optional &&
            areEquivalent(typeInfo, typeInfoB.properties[key])
        )
      );
    case 'boolean':
    default:
      return false;
  }
};

const findFactOptions = (factOptions: FactInfo[], typeInfo: TypeInfo) =>
  factOptions.filter((fact) => areEquivalent(typeInfo, fact));

const ValueInput = ({
  typeInfo,
  name,
  optional,
  data,
  onDataUpdate
}: Omit<TypeEditorProps, 'factOptions'>) => {
  const formattedName = `${camelCaseToStartCase(name)}${optional ? ' (optional)' : ''}`;
  switch (typeInfo.type) {
    case 'string':
      return (
        <StringEditor
          name={formattedName}
          data={data as string | undefined}
          optional={optional}
          onDataUpdate={onDataUpdate}
        />
      );
    case 'number':
      if (typeInfo.isDate) {
        return <DateEditor data={data as number | undefined} onDataUpdate={onDataUpdate} />;
      }
      return (
        <NumberEditor
          name={formattedName}
          unit={typeInfo.unit}
          data={data as number | undefined}
          optional={optional}
          onDataUpdate={onDataUpdate}
        />
      );
    case 'boolean':
      return (
        <BooleanEditor
          name={formattedName}
          data={data as boolean | undefined}
          onDataUpdate={onDataUpdate}
        />
      );
    case 'enumerated':
      return (
        <EnumeratedEditor
          name={formattedName}
          values={typeInfo.values}
          optional={optional}
          data={data as string | number | undefined}
          onDataUpdate={onDataUpdate}
        />
      );
    case 'object':
      return (
        <ObjectEditor
          name={formattedName}
          properties={typeInfo.properties}
          optional={optional}
          data={data as Record<string, unknown> | undefined}
          onDataUpdate={onDataUpdate}
        />
      );
    case 'list':
      if (typeInfo.typeInfo.type === 'enumerated') {
        // use the flags editor for list of enumerated values
        // this makes it easy to just multi-select values.
        return (
          <FlagsEditor
            name={formattedName}
            values={typeInfo.typeInfo.values}
            optional={optional}
            data={data as (string | number)[] | undefined}
            onDataUpdate={onDataUpdate}
          />
        );
      }
      return (
        <ListEditor
          name={formattedName}
          optional={optional}
          typeInfo={typeInfo.typeInfo}
          data={data as unknown[] | undefined}
          onDataUpdate={onDataUpdate}
        />
      );
    default:
      return null;
  }
};

const TypeEditorFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

const TypeEditorSelector = styled.div`
  flex-grow: 1;
  width: fit-content;
  min-width: 150px;
`;

const TypeEditorInput = styled.div`
  flex-grow: 8;
`;

const VALUE_TAG = '!!VALUE!!';

/**
 * Renders an editor for a specific value type
 * based on the typeInfo that is passed in this
 * if a fact could fill this spot this renders a dropdown to select a fact or Value
 * If value is selected this renders the correct value editor. If a fact is selected
 * and that fact has parameters this renders the fact parameters.
 */
export const TypeEditor = ({
  typeInfo,
  factOptions,
  name,
  optional,
  data,
  onDataUpdate
}: TypeEditorProps) => {
  const factId =
    data && typeof data === 'object' && 'fact' in data
      ? (data as { fact: string }).fact
      : undefined;
  const factCandidates = findFactOptions(factOptions, typeInfo);
  const fact = factCandidates.find((f) => f.factId === factId);
  return (
    <TypeEditorFlex>
      {factCandidates.length > 0 ? (
        <MaybeTooltip description={fact?.description}>
          <TypeEditorSelector>
            <OwnUpDropdown
              label={camelCaseToStartCase(name)}
              value={factId ?? VALUE_TAG}
              onChange={(event) => {
                onDataUpdate(
                  event.target.value === VALUE_TAG ? undefined : { fact: event.target.value }
                );
              }}
            >
              <OwnUpMenuItem value={VALUE_TAG}>Value</OwnUpMenuItem>
              {factCandidates.map(({ factId: id }) => (
                <OwnUpMenuItem key={id} value={id}>
                  {camelCaseToStartCase(id)}
                </OwnUpMenuItem>
              ))}
            </OwnUpDropdown>
          </TypeEditorSelector>
        </MaybeTooltip>
      ) : null}
      {fact ? (
        fact.params && (
          <TypeEditorInput>
            <ParameterEditor
              params={fact.params}
              factOptions={[]}
              data={(data as { params?: Record<string, unknown> }).params ?? {}}
              onDataUpdate={(params) => onDataUpdate({ fact: factId, params })}
            />
          </TypeEditorInput>
        )
      ) : (
        <TypeEditorInput>
          <ValueInput
            typeInfo={typeInfo}
            name={name}
            optional={optional}
            data={data}
            onDataUpdate={onDataUpdate}
          />
        </TypeEditorInput>
      )}
    </TypeEditorFlex>
  );
};
