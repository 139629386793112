/* eslint-disable @typescript-eslint/naming-convention */
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { loggedInFetch } from '../../modules/authentication';
import {
  beginLoadingAction,
  beginSavingAction,
  createLendersAction,
  endLoadingAction,
  endSavingAction,
  fetchAllLendersAction,
  loadLicensedStates,
  saveLenderAction,
  setErrorAction,
  setLastModifiedTimeAction
} from '../actions';
import {
  FETCH_ALL_LENDERS,
  FETCH_LICENSED_STATES,
  SAVE_LENDERS,
  SaveLendersAction,
  SYNC_PROD_DATA
} from '../actions/action-types';
import { lenderBudgetsSaga } from '../lender-configuration/saga';
import { lenderRulesSaga } from '../lender-rules/saga';
import { rulesValidationSaga } from '../rules-validation/saga';
import { dirtyLendersSelector } from '../selectors';
import { Lender } from '../store/lender/types';
import { parseLenders } from './helpers';

interface AllLendersResponseJson extends Record<string, unknown> {
  result: unknown;
}

/**
 * This saga handles grabbing all the lender data and building
 * out the state.
 */
function* fetchAllLendersSaga() {
  try {
    yield put(beginLoadingAction('lenders'));
    const res: Response = yield call(loggedInFetch, '/api/lender');
    /**
     * For now I think this is enough to catch unauthorized fetches.
     * In the future I think we will want more explicit error handling.
     */
    if (res.status < 400) {
      const resData: AllLendersResponseJson = yield res.json();
      const lenders = parseLenders(resData.result);
      yield put(createLendersAction(lenders));
    } else {
      yield put(setErrorAction());
    }
    yield put(endLoadingAction('lenders'));
  } catch {
    yield put(setErrorAction());
  }
}

export function* watchFetchAllLenders() {
  yield takeLatest(FETCH_ALL_LENDERS, fetchAllLendersSaga);
}

type FetchLicensedStatesJson = string[];

function* fetchLicensedStates() {
  try {
    yield put(beginLoadingAction('activeStates'));
    const res: Response = yield call(loggedInFetch, '/api/licensedStates');
    if (res.status < 400) {
      const data: FetchLicensedStatesJson = yield res.json();
      yield put(loadLicensedStates(data));
    } else {
      yield put(setErrorAction());
    }
    yield put(endLoadingAction('activeStates'));
  } catch {
    yield put(setErrorAction());
  }
}

export function* watchFetchLicensedStates() {
  yield takeLatest(FETCH_LICENSED_STATES, fetchLicensedStates);
}

/**
 * This saga handles updating the lender data on the backend
 */
export function* saveLendersSaga({ payload: { reason } }: SaveLendersAction) {
  const dirtyLenderRecord: Record<string, Lender> = yield select(dirtyLendersSelector);
  const dirtyLenders = Object.values(dirtyLenderRecord);

  yield all(dirtyLenders.map((lender) => put(saveLenderAction(lender, reason))));

  yield put(beginSavingAction());

  try {
    const results: Response[] = yield all(
      dirtyLenders.map(({ id, states: { enabledStates, autoQuoteConstraints } }) =>
        call(loggedInFetch, `/api/lender/${id}`, {
          method: 'PUT',
          body: JSON.stringify({
            enabledStates,
            autoQuoteConstraints,
            reason
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
      )
    );

    // Determine any error messages received.
    const errors = results.reduce((acc, result, index) => {
      if (!result.ok) {
        acc.push(`${dirtyLenders[index]?.id || 'unknown'}: ${result.statusText}`);
      }
      return acc;
    }, [] as string[]);
    if (errors.length > 0) {
      throw new Error(`Failed to successfully update all lenders: ${errors.join(';')}`);
    } else {
      yield put(fetchAllLendersAction());
      yield put(endSavingAction());
      yield put(setLastModifiedTimeAction(null));
    }
  } catch {
    yield put(setErrorAction());
  }
}

export function* watchSaveLenders() {
  yield takeLatest(SAVE_LENDERS, saveLendersSaga);
}

export function* syncProdDataSaga() {
  try {
    yield put(beginLoadingAction('lenders'));
    const res: Response = yield call(loggedInFetch, '/api/sync', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (res.status < 400) {
      yield put(fetchAllLendersAction());
    } else {
      yield put(setErrorAction());
    }
  } catch {
    yield put(setErrorAction());
  }
}

export function* watchSyncProdData() {
  yield takeLatest(SYNC_PROD_DATA, syncProdDataSaga);
}

export function* rootSaga() {
  yield all([
    fork(watchFetchAllLenders),
    fork(watchFetchLicensedStates),
    fork(watchSaveLenders),
    fork(watchSyncProdData),
    fork(lenderBudgetsSaga),
    fork(lenderRulesSaga),
    fork(rulesValidationSaga)
  ]);
}
