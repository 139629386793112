import { USStates } from '@rategravity/core-lib/enums';
import type { RJSFSchema } from '@rjsf/utils';

export const schema: RJSFSchema = {
  definitions: {
    baseLead: {
      type: 'object',
      required: [
        'leadID',
        'creditRating',
        'vaEligible',
        'propertyState',
        'propertyValue',
        'propertyType',
        'propertyUse',
        'loanAmount'
      ],
      properties: {
        // -- Lead tracking information
        /* Unique Id for this lead */
        leadID: { type: 'string' },
        creditRating: { enum: ['Excellent', 'Good', 'Fair', 'Poor'] },
        vaEligible: { type: 'boolean' },

        // -- Property information
        propertyCity: { type: 'string' },
        propertyState: { enum: USStates },
        propertyCounty: { type: 'string' },
        propertyZip: { type: 'string' },
        /** How much the property is worth */
        propertyValue: { type: 'number' },
        /** Type of property in question */
        propertyType: {
          enum: [
            'SingleFamily' as const,
            'MultiFamily' as const,
            'Condo' as const,
            'Manufactured' as const,
            'Townhome' as const,
            'NewConstruction' as const,
            'Mobile' as const
          ]
        },
        /** How the property is being used */
        propertyUse: { enum: ['Primary' as const, 'Secondary' as const, 'Investment' as const] },

        // -- Loan information
        /** Size of the loan */
        loanAmount: { type: 'number' },

        // -- Universal Lead identification
        jornayaLeadId: { type: 'string' },
        trustedFormCertificateUrl: { type: 'string' },

        // -- Overwriting engine facts
        hourOfDay: {
          type: 'number',
          description: 'Current hour of the day (Eastern time) where 0 is midnight and 23 is 11pm'
        },
        dayOfTheWeek: {
          type: 'number',
          description: 'A number 0-6 where 0 is Sunday and 6 is Saturday'
        },
        // -- Internal Lead Payload fields
        /** Source for this lead (e.g., Realtor.com) */
        source: { type: 'string' },
        /** Bankruptcy status */
        bankruptcy: { type: 'boolean' },
        /** Date Lead Received */
        dateLeadReceived: { type: 'number' },
        /** Employment status */
        employmentStatus: { enum: ['Employed', 'SelfEmployed', 'Retired'] },
        /** Lead exclusive to Own Up or shared */
        exclusive: { type: 'boolean' },
        /** First-time home buyer */
        fthb: { type: 'boolean' },
        /** Coborrower information attached */
        hasCoborrower: { type: 'boolean' },
        /** Shopper working with a real estate agent */
        hasAgent: { type: 'boolean' },
        /** IncomeType enumeration from onboarding-stack */
        incomeType: {
          enum: [
            'Salary',
            'Hourly',
            'Bonus',
            'Commission',
            'Pension',
            'SelfEmployed',
            'NoIncome',
            'Other'
          ]
        }
      }
    },
    purchaseLead: {
      title: 'Purchase Lead Schema',
      allOf: [{ $ref: '#/definitions/baseLead' }],
      required: ['loanPurpose'],
      properties: {
        loanPurpose: { const: 'Purchase' },
        /** Epoch timestamp of the close date for a purchase -- if applicable */
        closeDate: {
          type: 'number',
          description: '(Close date in MS time. Defaulted to 30 days out.)'
        },
        /** Number of months lead is planning to purchase */
        purchaseTimeline: {
          type: 'number',
          description: '(Number of months lead is planning to purchase)'
        },
        /** Type of questionnaire this was sourced from--if applicable */
        questionnaireType: { enum: ['accepted-offer', 'purchase', 'preapproval'] }
      }
    },
    refinanceLead: {
      title: 'Refinance Lead Schema',
      allOf: [{ $ref: '#/definitions/baseLead' }],
      required: ['loanPurpose', 'cashOutAmount', 'currentMortgageBalance'],
      properties: {
        loanPurpose: { const: 'Refinance' },
        cashOutAmount: { type: 'number', default: 0 },
        currentMortgageBalance: { type: 'number' },
        currentMortgageRate: { type: 'number' }, // decimal representation
        /** Type of questionnaire this was sourced from--if applicable */
        questionnaireType: { enum: ['refinance'] }
      }
    }
  },
  title: 'Evaluate Test Lead',
  type: 'object',
  oneOf: [{ $ref: '#/definitions/purchaseLead' }, { $ref: '#/definitions/refinanceLead' }],
  properties: {}
};
