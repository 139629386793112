import { NestedCondition } from 'json-rules-engine';
import React, { ReactNodeArray, useState } from 'react';
import styled from 'styled-components';
import { mapNestedConditions } from './utils';

const Wrapper = styled.ul`
  list-style: none;
`;

interface AnyConditionsNodeProps {
  data: NestedCondition[];
}
export const AnyConditionsNode: React.FC<AnyConditionsNodeProps> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  const children: ReactNodeArray = mapNestedConditions(data).map((child, index) => (
    <li key={index}>{child}</li>
  ));
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => handleClick(e)}>
      {isExpanded ? '▼' : '▶'} Any
      {isExpanded && <Wrapper>{children}</Wrapper>}
    </div>
  );
};
