import { ConditionProperties, RuleProperties } from 'json-rules-engine';
import { facts, ParamInfo, rules, TypeInfo } from './schema';

export const camelCaseToStartCase = (input: string): string => {
  const startCase = input.replace(/([a-z])([A-Z])/g, '$1 $2');
  return startCase.charAt(0).toUpperCase() + startCase.slice(1);
};

const unaryOperators = ['isNone', 'isDefined', 'isNotNone', 'isNotDefined'];

const formatValue = (value: unknown, type: TypeInfo): string => {
  if (typeof value === 'object' && value != null && 'fact' in value) {
    return formatFact(
      (value as { fact: string }).fact,
      (value as { params?: Record<string, unknown> }).params
    );
  }
  if (value == null) {
    return `${value}`;
  }
  if (Array.isArray(value)) {
    return value.map((v) => formatValue(v, type.type === 'list' ? type.typeInfo : type)).join(', ');
  }
  switch (type.type) {
    case 'boolean':
      return !!value ? 'Yes' : 'No';
    case 'number':
      switch (type.unit) {
        case 'money':
          return Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(
            value as number
          );
        case 'timestamp':
          return new Date(value as number).toLocaleString('sv', { timeZone: 'America/New_York' });
        case 'percent':
          return `${(value as number) * 100}%`;
      }
    case 'string':
      return `${value}${type.unit != null ? ` ${type.unit}` : ''}`;
    case 'object':
      return `{${Object.entries(type.properties)
        .map(
          ([key, typeInfo]) =>
            `${camelCaseToStartCase(key)}: ${formatValue(
              (value as Record<string, unknown>)[key],
              typeInfo
            )}`
        )
        .join(', ')}}`;
  }
  return `${value}`;
};

const formatFact = (fact: string, params?: Record<string, unknown>): string => {
  const factInfo = facts.find(({ factId }) => factId === fact);
  return formatParameterizedType(fact, factInfo ?? {}, params);
};

const formatParameterizedType = (
  name: string,
  typeInfo: { params?: Record<string, ParamInfo> },
  params: Record<string, unknown> = {}
): string => {
  const paramKeys =
    typeInfo.params === undefined
      ? []
      : Object.entries(typeInfo.params!).filter(
          ([key, { optional }]) => !optional || params[key] !== undefined
        );
  return `${camelCaseToStartCase(name)}${
    paramKeys.length > 0
      ? ` with ${paramKeys
          .map(([key, type]) => `${camelCaseToStartCase(key)}: ${formatValue(params[key], type)}`)
          .join(', ')}`
      : ''
  }`;
};

export const formatConditionProps = ({
  fact,
  operator,
  value,
  params
}: ConditionProperties): string => {
  const factInfo = facts.find(({ factId }) => factId === fact) ?? { type: 'string' };
  return `${formatFact(fact, params ?? {})} ${camelCaseToStartCase(operator)}${
    unaryOperators.includes(operator) ? '' : ` ${formatValue(value, factInfo)}`
  }`;
};

export const formatEventProps = ({ type, params }: RuleProperties['event']): string => {
  const typeInfo = rules([]).find((info) => info.type === type) ?? {};
  return formatParameterizedType(type, typeInfo, params);
};
