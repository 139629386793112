import { TopLevelCondition } from 'json-rules-engine';
import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  DELETE_LENDER_CONDITION_ACTION_TYPE,
  DELETE_LENDER_RULE_ACTION_TYPE,
  DeleteLenderConditionAction,
  DeleteLenderRuleAction,
  EDIT_LENDER_CONDITION_ACTION_TYPE,
  EDIT_LENDER_RULE_ACTION_TYPE,
  EditLenderConditionAction,
  EditLenderRuleAction,
  LENDER_RULES_LOADED_ACTION_TYPE,
  LenderRulesLoadedAction,
  LOAD_LENDER_RULES_ACTION_TYPE,
  LOAD_LENDER_RULES_FAILED_ACTION_TYPE,
  RENAME_LENDER_CONDITION_ACTION_TYPE,
  RENAME_LENDER_RULE_ACTION_TYPE,
  RenameLenderConditionAction,
  RenameLenderRuleAction,
  SAVE_LENDER_RULES_ACTION_TYPE,
  SAVED_LENDER_RULES_ACTION_TYPE
} from './actions';
import { applyRename } from './rename-condition';
import { initialState, LenderRulesState, LenderState } from './state';

const lenderRulesReducer = composeReducers(
  withDefault<LenderState>(initialState),
  ofType(LOAD_LENDER_RULES_FAILED_ACTION_TYPE, () => ({
    loading: false,
    error: true,
    savingRules: false,
    rules: {},
    conditions: {}
  })),
  ofType(
    LENDER_RULES_LOADED_ACTION_TYPE,
    (_, { payload: { rules, conditions } }: LenderRulesLoadedAction) => ({
      loading: false,
      error: false,
      savingRules: false,
      rules: Object.fromEntries(rules.map((rule) => [rule.name, { ...rule, edited: false }])),
      conditions: Object.fromEntries(
        conditions.map((condition) => [condition.name, { ...condition, edited: false }])
      )
    })
  ),
  ofType(EDIT_LENDER_RULE_ACTION_TYPE, (state: LenderState, { payload }: EditLenderRuleAction) => {
    return {
      ...state,
      rules: {
        ...state.rules,
        [payload.ruleId]: {
          ...(state.rules[payload.ruleId] ?? { name: '' }),
          ...payload.ruleProperties,
          status: 'edited' as const
        }
      }
    };
  }),
  ofType(
    EDIT_LENDER_CONDITION_ACTION_TYPE,
    (state: LenderState, { payload }: EditLenderConditionAction) => {
      return {
        ...state,
        conditions: {
          ...state.conditions,
          [payload.conditionId]: {
            ...(state.conditions[payload.conditionId] ?? { name: '' }),
            conditions: payload.conditions,
            status: 'edited' as const
          }
        }
      };
    }
  ),
  ofType(
    RENAME_LENDER_CONDITION_ACTION_TYPE,
    (state: LenderState, { payload }: RenameLenderConditionAction) => {
      const existingCondition = state.conditions[payload.conditionId];
      if (existingCondition) {
        const oldName = existingCondition.name;
        // find all references to the existing condition
        // and change them to references to the newly named condition
        const appliedRenameConditions = Object.fromEntries(
          Object.entries(state.conditions).map(([key, value]) => {
            const [applied, updated] = applyRename(oldName, payload.name, value.conditions);
            if (applied) {
              return [
                key,
                { ...value, conditions: updated as TopLevelCondition, status: 'edited' as const }
              ];
            } else {
              return [key, value];
            }
          })
        );
        // if the condition wasn't used in rules we don't
        // want to touch that part of the redux store.
        let rulesEdited = false;
        const appliedRenameRules = Object.fromEntries(
          Object.entries(state.rules).map(([key, value]) => {
            const [applied, updated] = applyRename(oldName, payload.name, value.conditions);
            if (applied) {
              rulesEdited = true;
              return [
                key,
                { ...value, conditions: updated as TopLevelCondition, status: 'edited' as const }
              ];
            } else {
              return [key, value];
            }
          })
        );
        return {
          ...state,
          rules: rulesEdited ? appliedRenameRules : state.rules,
          conditions: {
            [`${payload.conditionId}##RENAME_DELETE`]: {
              // renaming the condition is done by creating a dummy
              // condition that will be deleted
              name: existingCondition.name,
              conditions: { all: [] },
              status: 'deleted' as const
            },
            ...appliedRenameConditions,
            [payload.conditionId]: {
              ...existingCondition,
              name: payload.name,
              status: 'edited' as const
            }
          }
        };
      } else {
        return {
          ...state,
          conditions: {
            ...state.conditions,
            [payload.conditionId]: {
              conditions: { all: [] },
              name: payload.name,
              status: 'edited' as const
            }
          }
        };
      }
    }
  ),
  ofType(RENAME_LENDER_RULE_ACTION_TYPE, (state, { payload }: RenameLenderRuleAction) => {
    const existingRule = state.rules[payload.ruleId];
    if (existingRule) {
      // Renames a rule by first
      // creating a dummy entry in redux with the old name and marking it
      // for deletion
      // and then editing the existing entry with the new name.
      // When turned into an api call this will delete the old rule
      // and create a new rule.
      return {
        ...state,
        rules: {
          [`${payload.ruleId}##RENAME_DELETE`]: {
            name: existingRule.name,
            conditions: { all: [] },
            event: { type: '' },
            status: 'deleted' as const,
            ruleSets: [],
            enabled: false
          },
          ...state.rules,
          [payload.ruleId]: {
            ...existingRule,
            name: payload.name,
            status: 'edited' as const,
            enabled: true
          }
        }
      };
    }
    return {
      ...state,
      rules: {
        ...state.rules,
        [payload.ruleId]: {
          conditions: { all: [] },
          event: { type: '' },
          name: payload.name,
          status: 'edited' as const,
          ruleSets: [],
          enabled: true
        }
      }
    };
  }),
  ofType(DELETE_LENDER_CONDITION_ACTION_TYPE, (state, { payload }: DeleteLenderConditionAction) => {
    return {
      ...state,
      conditions: {
        ...state.conditions,
        [payload.conditionId]: {
          ...state.conditions[payload.conditionId],
          status: 'deleted' as const
        }
      }
    };
  }),
  ofType(DELETE_LENDER_RULE_ACTION_TYPE, (state, { payload }: DeleteLenderRuleAction) => {
    return {
      ...state,
      rules: {
        ...state.rules,
        [payload.ruleId]: {
          ...state.rules[payload.ruleId],
          status: 'deleted' as const
        }
      }
    };
  }),
  ofType(SAVE_LENDER_RULES_ACTION_TYPE, (state) => ({
    ...state,
    savingRules: true
  })),
  ofType(SAVED_LENDER_RULES_ACTION_TYPE, (state) => {
    return {
      ...state,
      savingRules: false,
      conditions: Object.fromEntries(
        Object.entries(state.conditions)
          .filter(([, condition]) => condition.status !== 'deleted')
          .map(([id, condition]) => [
            id,
            {
              ...condition,
              status: 'clean' as const
            }
          ])
      ),
      rules: Object.fromEntries(
        Object.entries(state.rules)
          .filter(([, rule]) => rule.status !== 'deleted')
          .map(([id, rule]) => [
            id,
            {
              ...rule,
              status: 'clean' as const
            }
          ])
      )
    };
  })
);

export const lendersReducer = composeReducers(
  withDefault<LenderRulesState>({}),
  ofType(
    [
      LOAD_LENDER_RULES_ACTION_TYPE,
      LOAD_LENDER_RULES_FAILED_ACTION_TYPE,
      LENDER_RULES_LOADED_ACTION_TYPE,
      EDIT_LENDER_CONDITION_ACTION_TYPE,
      EDIT_LENDER_RULE_ACTION_TYPE,
      DELETE_LENDER_CONDITION_ACTION_TYPE,
      DELETE_LENDER_RULE_ACTION_TYPE,
      RENAME_LENDER_CONDITION_ACTION_TYPE,
      RENAME_LENDER_RULE_ACTION_TYPE,
      SAVE_LENDER_RULES_ACTION_TYPE,
      SAVED_LENDER_RULES_ACTION_TYPE
    ],
    (state, action: { type: string; payload: { lenderId: string } }) => {
      const {
        payload: { lenderId }
      } = action;
      return {
        ...state,
        [lenderId]: lenderRulesReducer(state[lenderId], action)
      };
    }
  )
);
