import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  ENABLED_LENDERS_LOADED_ACTION_TYPE,
  EnabledLendersLoadedAction,
  LENDER_BUDGET_UPDATED_ACTION_TYPE,
  LENDER_BUDGETS_LOADED_ACTION_TYPE,
  LenderBudgetsLoadedAction,
  LenderBudgetUpdatedAction,
  LOAD_LENDER_CONFIG_FAILED_ACTION_TYPE,
  UPDATE_LENDER_ENABLED_ACTION_TYPE,
  UpdateLenderEnabledAction
} from './actions';
import { initialState, LenderBudgetConfigState } from './state';
import { LenderBudget } from './types';

export const lenderBudgetReducer = composeReducers(
  withDefault<LenderBudgetConfigState>(initialState),
  ofType(
    LENDER_BUDGETS_LOADED_ACTION_TYPE,
    (state, { payload: { budgets } }: LenderBudgetsLoadedAction): LenderBudgetConfigState => ({
      ...state,
      loading: false,
      budgets: budgets.reduce(
        (acc, val) => {
          if (!acc[val.lenderId]) {
            acc[val.lenderId] = {};
          }
          acc[val.lenderId][val.budgetType] = val;
          return acc;
        },
        {} as Record<string, Record<string, LenderBudget>>
      )
    })
  ),
  ofType(
    LOAD_LENDER_CONFIG_FAILED_ACTION_TYPE,
    (state): LenderBudgetConfigState => ({
      ...state,
      loading: false,
      error: true
    })
  ),
  ofType(
    LENDER_BUDGET_UPDATED_ACTION_TYPE,
    (state, { payload: { budget } }: LenderBudgetUpdatedAction): LenderBudgetConfigState => ({
      ...state,
      budgets: {
        ...state.budgets,
        [budget.lenderId]: {
          ...state.budgets[budget.lenderId],
          [budget.budgetType]: budget
        }
      }
    })
  ),
  ofType(
    ENABLED_LENDERS_LOADED_ACTION_TYPE,
    (
      state,
      { payload: { enabledLenders } }: EnabledLendersLoadedAction
    ): LenderBudgetConfigState => ({
      ...state,
      enabledLenders
    })
  ),
  ofType(
    UPDATE_LENDER_ENABLED_ACTION_TYPE,
    (
      state,
      { payload: { lenderId, enabled } }: UpdateLenderEnabledAction
    ): LenderBudgetConfigState => ({
      ...state,
      enabledLenders: enabled
        ? [...state.enabledLenders, lenderId]
        : state.enabledLenders.filter((item) => item != lenderId)
    })
  )
);
