import { NestedCondition } from 'json-rules-engine';

/**
 * Replace references to a condition with references to the new condition
 * return the resulting condition and a flag if it's updated.
 */
export const applyRename = (
  currentName: string,
  newName: string,
  conditions: NestedCondition
): [boolean, NestedCondition] => {
  let applied = false;
  let result = conditions;
  if ('condition' in conditions) {
    if (conditions.condition === currentName) {
      applied = true;
      result = { ...conditions, condition: newName };
    }
  } else if ('all' in conditions) {
    const results = conditions.all.map((c) => applyRename(currentName, newName, c));
    applied = results.some(([a]) => a);
    result = {
      ...conditions,
      all: results.map(([_, c]) => c)
    };
  } else if ('any' in conditions) {
    const results = conditions.any.map((c) => applyRename(currentName, newName, c));
    applied = results.some(([a]) => a);
    result = {
      ...conditions,
      any: results.map(([_, c]) => c)
    };
  } else if ('not' in conditions) {
    const results = applyRename(currentName, newName, conditions.not);
    applied = results[0];
    result = { ...conditions, not: results[1] };
  }
  return [applied, applied ? result : conditions];
};
