import { OwnUpModal, OwnUpTextArea } from '@rategravity/own-up-component-library-legacy';
import React, { useMemo, useState } from 'react';
import { ReasonModalProps } from './properties';

export const ReasonModal = ({ modalController, publish }: ReasonModalProps) => {
  const [reason, setReason] = useState<string | undefined>('');
  const submitDisabled = useMemo(() => !reason, [reason]);
  return (
    <OwnUpModal
      header="Please add a comment."
      controller={modalController}
      submitDisabled={submitDisabled}
      cancellable
      onSubmit={async () => (reason ? await publish(reason) : false)}
    >
      <OwnUpTextArea
        name="reason for changes"
        value={reason}
        placeholder="Why did you make these changes?"
        onChange={(value) => setReason(value)}
      />
    </OwnUpModal>
  );
};
