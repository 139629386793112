/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import styled from 'styled-components';
import { LenderCondition, LenderRule } from '../../redux/lender-rules/state';
import { camelCaseToStartCase, mapNestedConditions } from './utils';

const TopLevelConditionWrapper = styled.div`
  margin-left: 20px;
`;

const ListItemWrapper = styled.li`
  list-style: none;
`;

const SummaryContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const ConditionSummary = ({
  name,
  condition,
  children,
  enabled
}: React.PropsWithChildren<{
  name: string;
  condition: LenderRule | LenderCondition;
  enabled: boolean;
}>) => {
  const [expanded, setExpanded] = useState(false);
  let elements: React.ReactNode[] = [];
  let label: string = '';
  if ('all' in condition.conditions) {
    elements = mapNestedConditions(condition.conditions.all);
    label = 'All';
  } else if ('any' in condition.conditions) {
    elements = mapNestedConditions(condition.conditions.any);
    label = 'Any';
  }

  // Highlight the rule if it has unsaved edits
  let nameElement: string | JSX.Element = camelCaseToStartCase(name);
  if (condition.status === 'edited') {
    nameElement = <strong>* {nameElement}</strong>;
  }

  const showRuleSets = 'ruleSets' in condition;
  // This is the expandable rule/condition
  // Need to define it here, as for rules it will be inside a table,
  //  but for conditions it is stand alone.
  const RuleComponent = () => (
    <li onClick={() => setExpanded((e) => !e)} style={{ cursor: 'pointer' }}>
      {expanded ? '▼' : '▶'} {nameElement}
      {expanded && (
        <div style={{ cursor: 'auto' }}>
          <TopLevelConditionWrapper>
            {label}
            <ul>
              {elements.map((element, index) => (
                <ListItemWrapper key={index}>{element}</ListItemWrapper>
              ))}
            </ul>
          </TopLevelConditionWrapper>
          {children}
        </div>
      )}
    </li>
  );

  return (
    <React.Fragment>
      {showRuleSets ? (
        <tr>
          <td>{condition.ruleSets.join(', ')}</td>
          <td style={{ color: enabled ? 'green' : 'red' }}>{enabled ? '✔' : '✘'}</td>
          <td>
            <RuleComponent />
          </td>
        </tr>
      ) : (
        <SummaryContainer>
          <RuleComponent />
        </SummaryContainer>
      )}
    </React.Fragment>
  );
};
