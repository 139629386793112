import {
  OwnUpDefaultInfoNotification,
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpSmallHeadlineBook,
  OwnUpSpinner
} from '@rategravity/own-up-component-library';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  LenderConditionsList,
  LenderRuleList
} from '../../components/lender-configuration/lender-conditions-list';
import { DEFAULT_LENDER_ID } from '../../modules/rules/schema/constants';
import {
  deleteLenderCondition,
  deleteLenderRule,
  editLenderRule,
  renameLenderRule,
  saveLenderRulesAction
} from '../../redux/lender-rules/actions';
import {
  inUseConditionsSelector,
  lenderConditionsSelector,
  lenderRulesSelector,
  pendingSaveSelector,
  savingSelector
} from '../../redux/lender-rules/selector';
import { AppState } from '../../redux/store';
import { Container } from './budgets';
import { RulesValidationModal } from './rules-validation-modal';

const RulesWrapper = styled.div`
  margin: 24px 0 24px;
`;

const SaveContainer = styled.div`
  display: flex;
  gap: 16px;
`;

interface LenderRulesEditorParams {
  lenderId: string;
  onConditionEdit: (conditionId: string) => void;
  onRuleEdit: (ruleId: string) => void;
}

const LoadedConditions = ({
  lenderId,
  onConditionEdit
}: Omit<LenderRulesEditorParams, 'onRuleEdit'>) => {
  const lenderConditions = useCallback(
    (state: AppState) => lenderConditionsSelector(state, { lenderId }),
    [lenderId]
  );
  const conditions = useSelector(lenderConditions, shallowEqual);
  const inUseConditions = useSelector((state: AppState) =>
    inUseConditionsSelector(state, { lenderId })
  );
  const dispatch = useDispatch();

  return (
    <RulesWrapper>
      <OwnUpSmallHeadlineBook variant="h2">Conditions</OwnUpSmallHeadlineBook>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <LenderConditionsList
            data={conditions}
            inUseConditions={inUseConditions}
            onEdit={onConditionEdit}
            onDelete={(conditionId) => dispatch(deleteLenderCondition(lenderId, conditionId))}
          />
          <OwnUpFillButtonPrimary
            onClick={() => onConditionEdit(uuid())}
            disabled={lenderId === DEFAULT_LENDER_ID}
            disabledReason="Default lender rules cannot be created from the UI"
          >
            New Condition
          </OwnUpFillButtonPrimary>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </RulesWrapper>
  );
};

const LoadedRules = ({
  lenderId,
  onRuleEdit
}: Omit<LenderRulesEditorParams, 'onConditionEdit'>) => {
  const lenderRules = useCallback(
    (state: AppState) => lenderRulesSelector(state, { lenderId }),
    [lenderId]
  );
  const rules = useSelector(lenderRules, shallowEqual);
  const dispatch = useDispatch();

  return (
    <RulesWrapper>
      <OwnUpSmallHeadlineBook variant="h2">Rules</OwnUpSmallHeadlineBook>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <LenderRuleList
            data={rules}
            onEdit={onRuleEdit}
            onClone={({ name, conditions, event }) => {
              const id = uuid();
              dispatch(renameLenderRule(lenderId, id, `${name} Copy`));
              dispatch(editLenderRule(lenderId, id, { conditions, event }));
              onRuleEdit(id);
            }}
            onDelete={(ruleId) => dispatch(deleteLenderRule(lenderId, ruleId))}
          />
          <OwnUpFillButtonPrimary
            onClick={() => onRuleEdit(uuid())}
            disabled={lenderId === DEFAULT_LENDER_ID}
            disabledReason="Default lender rules cannot be created from the UI"
          >
            New Rule
          </OwnUpFillButtonPrimary>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </RulesWrapper>
  );
};

export const LenderRulesEditorPage = ({
  lenderId,
  onConditionEdit,
  onRuleEdit
}: LenderRulesEditorParams) => {
  const dispatch = useDispatch();
  const [showRulesValidationModal, setShowRulesValidationModal] = useState(false);
  const pendingSave = useSelector((state: AppState) => pendingSaveSelector(state, { lenderId }));
  const activelySaving = useSelector((state: AppState) => savingSelector(state, { lenderId }));

  const onSave = useCallback(() => {
    dispatch(saveLenderRulesAction(lenderId));
    setShowRulesValidationModal(true);
  }, [dispatch, lenderId]);

  // This hooks into the beforeunload event and prompts the user to confirm leaving the page
  //  if there is a pendingSave
  useEffect(() => {
    const preventUnload = (event: BeforeUnloadEvent) => {
      if (pendingSave) {
        event.preventDefault();
        event.returnValue =
          'Rules have been edited without being saved, are you sure you want to exit?';
      }
    };

    window.addEventListener('beforeunload', preventUnload);

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, [pendingSave]);

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            {`Lender Configuration: ${lenderId}`}
          </OwnUpHeadlineBook>
          <Container>
            <LoadedConditions lenderId={lenderId} onConditionEdit={onConditionEdit} />
            <LoadedRules lenderId={lenderId} onRuleEdit={onRuleEdit} />
            <SaveContainer>
              <OwnUpFillButtonPrimary disabled={!pendingSave || activelySaving} onClick={onSave}>
                Save
              </OwnUpFillButtonPrimary>
              {activelySaving && (
                <div>
                  Saving ...
                  <OwnUpSpinner />
                </div>
              )}
              {pendingSave && (
                <OwnUpDefaultInfoNotification description="Edits have been made but not saved" />
              )}
            </SaveContainer>
            <RulesValidationModal
              open={showRulesValidationModal}
              onClose={() => setShowRulesValidationModal(false)}
            />
          </Container>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
