import { format } from 'fecha';
import React from 'react';
import { useEffect, useState } from 'react';

export const DAY = 1000 * 60 * 60 * 60 * 24;

export const formatTimeSince = (time: number | null) => {
  const now = Date.now();

  if (time === null || time > now) {
    return {
      message: 'never',
      next: null
    };
  }

  // Less than one minute ago.
  if (now - time < 60000) {
    return {
      message: 'seconds ago',
      next: time + 60000
    };
  }

  // If it's the same day.
  const t = new Date(time);
  const n = new Date(now);
  if (
    t.getFullYear() === n.getFullYear() &&
    t.getMonth() === n.getMonth() &&
    t.getDate() === n.getDate()
  ) {
    const next = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1);

    return {
      message: format(t, 'h:mm A'),
      next: next.getTime() - now
    };
  }

  return {
    message: format(t, 'mediumDate'),
    next: null
  };
};

export const getTimeSince = (time: number | null) => {
  const { message, next } = formatTimeSince(time);
  const [currentMessage, setCurrentMessage] = useState(message);

  useEffect(() => {
    setCurrentMessage(message);

    if (next) {
      const timer = setTimeout(() => {
        const { message: newMessage } = formatTimeSince(time);
        setCurrentMessage(newMessage);
      }, next - Date.now());

      return () => clearTimeout(timer);
    }
  }, [next, message, setCurrentMessage, time]);

  return <React.Fragment>{currentMessage}</React.Fragment>;
};
