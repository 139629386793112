import { OwnUpAppBar, OwnUpSection } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Auth, Unauthorized } from '../pages/authentication';
import { Home } from '../pages/lender-state-config';

export const App = () => {
  return (
    <React.Fragment>
      <OwnUpAppBar variant="minimal" />
      <OwnUpSection variant={['centered']}>
        <Switch>
          <Route path="/auth">
            <Auth />
          </Route>
          <Route path="/unauthorized">
            <Unauthorized />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </OwnUpSection>
    </React.Fragment>
  );
};
