import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import { LenderColumnState } from '../../components/lender-table/properties';
import { Lender } from '../store/lender/types';
import {
  BEGIN_LOADING,
  BEGIN_SAVING,
  BeginLoadingAction,
  BeginSavingAction,
  CREATE_LENDERS,
  CreateLendersAction,
  END_LOADING,
  END_SAVING,
  EndLoadingAction,
  EndSavingAction,
  FETCH_ALL_LENDERS,
  FETCH_LICENSED_STATES,
  FetchAllLendersAction,
  FetchLicensedStates,
  LOADED_LICENSED_STATES,
  LoadedLicensedStates,
  SAVE_LENDER,
  SAVE_LENDERS,
  SaveLenderAction,
  SaveLendersAction,
  SELECT_ACTIVE_CONFIG,
  SelectActiveConfigAction,
  SET_ERROR,
  SET_LAST_MODIFIED_TIME,
  SetErrorAction,
  SetLastModifiedTimeAction,
  SYNC_PROD_DATA,
  SyncProdDataAction,
  UPDATE_LENDER,
  UpdateLenderAction
} from './action-types';

export const beginLoadingAction = (slice: 'lenders' | 'activeStates'): BeginLoadingAction => ({
  type: BEGIN_LOADING,
  payload: { slice }
});

export const endLoadingAction = (slice: 'lenders' | 'activeStates'): EndLoadingAction => ({
  type: END_LOADING,
  payload: { slice }
});

export const createLendersAction = (props: Lender[]): CreateLendersAction => ({
  type: CREATE_LENDERS,
  payload: props
});

export const beginSavingAction = (): BeginSavingAction => ({
  type: BEGIN_SAVING
});

export const endSavingAction = (): EndSavingAction => ({
  type: END_SAVING
});

export const updateLenderAction = ({
  id,
  state,
  setting
}: {
  id: string;
  state: USStateAndTerritoriesType;
  setting: LenderColumnState;
}): UpdateLenderAction => ({
  type: UPDATE_LENDER,
  payload: { id, state, setting }
});

export const saveLenderAction = (lender: Lender, reason: string): SaveLenderAction => ({
  type: SAVE_LENDER,
  payload: { lender, reason }
});

export const saveLendersAction = (reason: string): SaveLendersAction => ({
  type: SAVE_LENDERS,
  payload: { reason }
});

export const selectActiveConfigAction = (props: string): SelectActiveConfigAction => ({
  type: SELECT_ACTIVE_CONFIG,
  payload: props
});

export const fetchAllLendersAction = (): FetchAllLendersAction => ({
  type: FETCH_ALL_LENDERS
});

export const setErrorAction = (): SetErrorAction => ({
  type: SET_ERROR
});

export const setLastModifiedTimeAction = (
  lastModifiedTime: number | null
): SetLastModifiedTimeAction => ({
  type: SET_LAST_MODIFIED_TIME,
  payload: { lastModifiedTime }
});

export const syncProdDataAction = (): SyncProdDataAction => ({
  type: SYNC_PROD_DATA
});

export const fetchLicensedStates = (): FetchLicensedStates => ({ type: FETCH_LICENSED_STATES });

export const loadLicensedStates = (licensedStates: string[]): LoadedLicensedStates => ({
  type: LOADED_LICENSED_STATES,
  payload: { licensedStates }
});
