import React from 'react';

interface DateEditorProps {
  data: number | undefined;
  onDataUpdate: (data: number) => void;
}

/**
 * Editor for the date type, using a date picker
 */
export const DateEditor = ({ data, onDataUpdate }: DateEditorProps) => {
  let value: string | undefined;
  if (data) {
    const date = new Date(data);
    value = date.toLocaleString('sv', { timeZone: 'America/New_York' });
  }
  return (
    <input
      type="datetime-local"
      value={value}
      onChange={(event) => {
        const chosenDate = Date.parse(event.target.value);
        onDataUpdate(chosenDate);
      }}
    />
  );
};
