import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import {
  createOwnUpComponent,
  OwnUpBox,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { renderEditableCell } from './cells';
import { LenderColumnState, LenderRowInfo } from './properties';

const DividerCell = createOwnUpComponent(OwnUpText, {
  backgroundColor: '#000000'
});

export const renderRows = ({
  editable,
  headers,
  rows,
  updateLender
}: {
  editable: boolean;
  headers: string[];
  rows: Record<string, LenderRowInfo>;
  updateLender: (
    id: string,
    state: USStateAndTerritoriesType,
    newSetting: LenderColumnState
  ) => void;
}) =>
  Object.entries(rows).map(([rowKey, rowData], rIndex) => {
    const firstRow = (
      <OwnUpBox variant="start" key={`r-${rIndex}`}>
        <OwnUpText>{rowData.name}</OwnUpText>
      </OwnUpBox>
    );

    const renderedRows = rowData.stateSettings.map((stateSetting, cIndex) => {
      const cellState = headers[cIndex + 1];

      const updateState = updateLender.bind(null, rowKey, cellState as USStateAndTerritoriesType);
      const cellKey = `c-${rIndex}-${cIndex}`;

      switch (stateSetting) {
        case LenderColumnState.DIVIDER:
          return <DividerCell>&apos;&apos;</DividerCell>;
        case LenderColumnState.UNLICENSED:
          return (
            <OwnUpText variant="thin" key={cellKey}>
              &mdash;
            </OwnUpText>
          );
        case LenderColumnState.AUTOQUOTABLE:
        case LenderColumnState.AQ_PURCHASE:
        case LenderColumnState.AQ_NON_INVESTMENT:
        case LenderColumnState.AQ_REFINANCE:
        case LenderColumnState.MANUAL:
        case LenderColumnState.DISABLED:
          return renderEditableCell(
            stateSetting,
            rowData.autoQuotable,
            updateState,
            editable,
            cellKey
          );
      }
    });
    return [firstRow, ...renderedRows];
  });
