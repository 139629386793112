import {
  OwnUpCheckbox,
  OwnUpComponentLibraryProvider,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpRadioButton,
  OwnUpRadioGroup,
  OwnUpSpinner
} from '@rategravity/own-up-component-library';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { fetchAllLenderBudgetsApi, fetchEnabledLendersApi } from '../../modules/api/lender-budget';
import { fetchLenderConditionsApi } from '../../modules/api/lender-rules';

const WEB_LEAD_RULE_SET = 'web-leads';

interface LenderRule {
  lenderId: string;
  ruleId: string;
  /** Whether or not this is a "delay queue" version of the rule */
  queue: boolean;
  campaign: string;
  budget: string;
  score: number;
  ruleSets: string[];
}

const RuleTable = styled.table`
  width: 100%;

  a {
    text-decoration: none;
  }

  td {
    padding: 2px;
  }

  thead {
    color: white;
    background-color: gray;
  }

  tr:nth-child(even) {
    background-color: lightgray;
  }
`;

export interface RulesPriorityPageProps {
  loading: boolean;
  rules: LenderRule[];
}

export const RulesTable = ({ rules }: Pick<RulesPriorityPageProps, 'rules'>) => {
  const ruleSetOptions = useMemo(() => [...new Set(rules.flatMap((x) => x.ruleSets))], [rules]);
  const [showQueue, setShowQueue] = useState(true);
  const [ruleSet, setRuleSet] = useState(WEB_LEAD_RULE_SET);
  const tableRows = useMemo(
    () =>
      rules
        .filter(
          (x) =>
            // Only show queue rules if the user has selected to show them _and_ we're
            //   filtered on web-leads rules (since the queue rules are only for web-leads)
            (x.queue ? showQueue && ruleSet === WEB_LEAD_RULE_SET : true) &&
            x.ruleSets.includes(ruleSet)
        )
        .sort((a, b) => b.score - a.score)
        .map((x, i) => (
          <tr key={i}>
            <td>
              <a href={`/lender-configuration/${x.lenderId}`}>{x.lenderId}</a>
            </td>
            <td>
              <a href={`/lender-configuration/${x.lenderId}/editor/rule/${x.ruleId}`}>{x.ruleId}</a>
              {x.queue && <small> (Delay Queue)</small>}
            </td>
            <td>{x.campaign}</td>
            <td>{x.budget}</td>
            <td>{x.ruleSets.join(', ')}</td>
            <td>{x.score}</td>
          </tr>
        )),
    [rules, showQueue, ruleSet]
  );
  return (
    <React.Fragment>
      <OwnUpGridItem xs={12}>
        <p>
          This page shows the rules for all currently enabled lenders in order of score. Note that
          these scores represent <em>ideal conditions</em> and may be dynamically adjusted at
          evaluation time based on current lender capacity and budgets.
        </p>
      </OwnUpGridItem>
      <OwnUpGridItem md={6}>
        <OwnUpRadioGroup value={ruleSet} onChange={(e) => setRuleSet(e.target.value)} row>
          {ruleSetOptions.map((x) => (
            <OwnUpRadioButton key={x} value={x}>
              {x}
            </OwnUpRadioButton>
          ))}
        </OwnUpRadioGroup>
      </OwnUpGridItem>
      <OwnUpGridItem md={6}>
        <OwnUpCheckbox checked={showQueue} onChange={() => setShowQueue(!showQueue)}>
          Show Delay Queue Rules
        </OwnUpCheckbox>
      </OwnUpGridItem>
      <OwnUpGridItem xs={12}>
        <RuleTable>
          <thead>
            <tr>
              <th>Lender</th>
              <th>Rule</th>
              <th>Campaign</th>
              <th>Budget</th>
              <th>Rule Sets</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {tableRows.length === 0 ? (
              <tr>
                <td colSpan={6}>(None found)</td>
              </tr>
            ) : (
              tableRows
            )}
          </tbody>
        </RuleTable>
      </OwnUpGridItem>
    </React.Fragment>
  );
};

export const RulesPriorityPageImpl = ({ loading, rules }: RulesPriorityPageProps) => {
  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            Rules Priority
          </OwnUpHeadlineBook>
        </OwnUpGridItem>
        {loading ? (
          <OwnUpGridItem xs={12}>
            <OwnUpSpinner />
          </OwnUpGridItem>
        ) : (
          <RulesTable rules={rules} />
        )}
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};

/**
 * A page that fetches all active lenders and their rules, and shows the rules in
 *   order of score priority. Also allows for filtering of rules based on ruleset.
 */
export const RulesPriorityPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [rules, setRules] = useState<LenderRule[]>([]);
  useEffect(() => {
    // On page load, fetch all enabled lenders and their rules
    void (async () => {
      const [lenders, lenderBudgets] = await Promise.all([
        fetchEnabledLendersApi(),
        fetchAllLenderBudgetsApi()
      ]);
      await Promise.all(
        lenders.map(async (lenderId) => {
          const results = await fetchLenderConditionsApi(lenderId);
          const mappedRules = results.rules.flatMap<LenderRule>((x) => {
            const pickedRules = [
              {
                lenderId,
                ruleId: x.name,
                campaign: x.event.params?.campaignName || 'N/A',
                budget: x.event.params?.budgetType || 'default',
                queue: false,
                ruleSets: x.ruleSets,
                score: x.event.params?.score || 0
              }
            ];
            // For web leads, include the "delay queue" rule as well.
            //   This is the same rule but its score is divided by 10
            //   to ensure they are deprioritized below lenders who have
            //   capacity today. Also force the ruleSet to just be
            //   "web-leads", since they will only apply to those.
            if (
              x.ruleSets.includes(WEB_LEAD_RULE_SET) &&
              lenderBudgets.find(
                (y) =>
                  y.lenderId === lenderId &&
                  y.budgetType === x.event.params?.budgetType &&
                  y.queueCapacity.count > 0
              )
            ) {
              pickedRules.push({
                ...pickedRules[0],
                queue: true,
                ruleSets: [WEB_LEAD_RULE_SET],
                score: x.event.params?.score / 10 || 0
              });
            }
            return pickedRules;
          });
          setRules((prev) => [...prev, ...mappedRules]);
        })
      );
      setIsLoading(false);
    })();
  }, []);
  return (
    <OwnUpComponentLibraryProvider>
      <RulesPriorityPageImpl loading={isLoading} rules={rules} />
    </OwnUpComponentLibraryProvider>
  );
};
