import {
  OwnUpBodyMedium,
  OwnUpCheckbox,
  OwnUpDropdown,
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpMenuItem,
  OwnUpTextInput
} from '@rategravity/own-up-component-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ConditionEditor } from '../../components/condition-editor';
import { EventEditor } from '../../components/event-editor';
import { facts, rules } from '../../modules/rules/schema';
import { DEFAULT_LENDER_ID, RULE_SETS } from '../../modules/rules/schema/constants';
import { loadBudgetsAction } from '../../redux/lender-configuration/actions';
import { lenderBudgetSelector } from '../../redux/lender-configuration/selector';
import { editLenderRule, renameLenderRule } from '../../redux/lender-rules/actions';
import { lenderConditionsSelector, lenderRuleSelector } from '../../redux/lender-rules/selector';
import { AppState } from '../../redux/store';
import { Container } from './budgets';

interface LenderRuleEditorParams {
  lenderId: string;
  ruleId: string;
  onDone: () => void;
}

export const LenderRuleEditorPage = ({ lenderId, ruleId, onDone }: LenderRuleEditorParams) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadBudgetsAction());
  }, [dispatch]);
  const lenderBudgets = useSelector(lenderBudgetSelector(lenderId));
  const budgetTypes = lenderBudgets ? Object.keys(lenderBudgets) : [];

  const conditions = useSelector(
    createSelector(
      (state: AppState) => lenderConditionsSelector(state, { lenderId }),
      (state: AppState) => lenderConditionsSelector(state, { lenderId: DEFAULT_LENDER_ID }),
      (conditionsForLender, defaultConditions) =>
        Array.from(
          new Set([
            ...conditionsForLender.map(({ name }) => name),
            ...defaultConditions.map(({ name }) => name)
          ])
        )
    )
  );
  const rule = useSelector((state: AppState) =>
    lenderRuleSelector(state, { lenderId, ruleId })
  ) ?? { name: '', conditions: { all: [] }, ruleSets: [] };

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            {`Rule Editor: ${lenderId} / ${rule.name}`}
          </OwnUpHeadlineBook>
          <Container>
            <div>
              <OwnUpDropdown
                label={'Rule sets'}
                value={rule.ruleSets}
                multiple={true}
                renderValue={(value) => (value as string[]).join(', ')}
                onChange={(event) => {
                  dispatch(
                    editLenderRule(lenderId, ruleId, {
                      event: rule.event,
                      conditions: rule.conditions,
                      ruleSets: event.target.value as string[]
                    })
                  );
                }}
              >
                {RULE_SETS.map((value) => (
                  <OwnUpMenuItem key={value.toString()} value={value.toString()}>
                    {value}
                    {rule.ruleSets.includes(value) ? '   ✔' : ''}
                  </OwnUpMenuItem>
                ))}
              </OwnUpDropdown>
              <OwnUpTextInput
                label="Name"
                value={rule.name}
                onChange={(event) =>
                  dispatch(renameLenderRule(lenderId, ruleId, event.target.value))
                }
              />
              <OwnUpCheckbox
                checked={rule.enabled}
                onChange={(event) => {
                  dispatch(
                    editLenderRule(lenderId, ruleId, {
                      event: rule.event,
                      conditions: rule.conditions,
                      enabled: event.target.checked
                    })
                  );
                }}
              >
                Enabled
              </OwnUpCheckbox>
              <br />
              <ConditionEditor
                facts={facts}
                conditions={conditions}
                condition={rule.conditions}
                validationErrors={new Map()}
                onConditionUpdate={(update) =>
                  dispatch(
                    editLenderRule(lenderId, ruleId, { event: rule.event, conditions: update })
                  )
                }
              />
              <br />
              <OwnUpBodyMedium variant="body1">Emit Event</OwnUpBodyMedium>
              <EventEditor
                factOptions={facts}
                ruleOptions={rules(budgetTypes)}
                event={rule.event}
                onEventUpdate={(event) =>
                  dispatch(editLenderRule(lenderId, ruleId, { conditions: rule.conditions, event }))
                }
              />
              <OwnUpFillButtonPrimary onClick={onDone}>Done</OwnUpFillButtonPrimary>
            </div>
          </Container>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
