import { LenderCondition, LenderRule } from './state';

export const LOAD_LENDER_RULES_ACTION_TYPE = 'LOAD_LENDER_RULES' as const;
export const loadLenderRulesAction = (lenderId: string) => ({
  type: LOAD_LENDER_RULES_ACTION_TYPE,
  payload: { lenderId }
});
export type LoadLenderRulesAction = ReturnType<typeof loadLenderRulesAction>;

export const LENDER_RULES_LOADED_ACTION_TYPE = 'LENDER_RULES_LOADED' as const;
export const lenderRulesLoadedAction = (
  lenderId: string,
  {
    rules,
    conditions
  }: { rules: Omit<LenderRule, 'status'>[]; conditions: Omit<LenderCondition, 'status'>[] }
) => ({
  type: LENDER_RULES_LOADED_ACTION_TYPE,
  payload: {
    lenderId,
    rules,
    conditions
  }
});
export type LenderRulesLoadedAction = ReturnType<typeof lenderRulesLoadedAction>;

export const LOAD_LENDER_RULES_FAILED_ACTION_TYPE = 'LOAD_LENDER_RULES_FAILED';
export const loadLenderRulesFailedAction = (lenderId: string) => ({
  type: LOAD_LENDER_RULES_FAILED_ACTION_TYPE,
  payload: {
    lenderId
  }
});

export const EDIT_LENDER_RULE_ACTION_TYPE = 'EDIT_LENDER_RULE' as const;
export const editLenderRule = (
  lenderId: string,
  ruleId: string,
  ruleProperties: Partial<Pick<LenderRule, 'conditions' | 'event' | 'ruleSets' | 'enabled'>>
) => ({
  type: EDIT_LENDER_RULE_ACTION_TYPE,
  payload: {
    lenderId,
    ruleId,
    ruleProperties
  }
});
export type EditLenderRuleAction = ReturnType<typeof editLenderRule>;

export const EDIT_LENDER_CONDITION_ACTION_TYPE = 'EDIT_LENDER_CONDITION' as const;
export const editLenderCondition = (
  lenderId: string,
  conditionId: string,
  conditions: LenderCondition['conditions']
) => ({
  type: EDIT_LENDER_CONDITION_ACTION_TYPE,
  payload: {
    lenderId,
    conditionId,
    conditions
  }
});
export type EditLenderConditionAction = ReturnType<typeof editLenderCondition>;

export const RENAME_LENDER_CONDITION_ACTION_TYPE = 'RENAME_LENDER_CONDITION' as const;
export const renameLenderCondition = (lenderId: string, conditionId: string, name: string) => ({
  type: RENAME_LENDER_CONDITION_ACTION_TYPE,
  payload: {
    lenderId,
    conditionId,
    name
  }
});
export type RenameLenderConditionAction = ReturnType<typeof renameLenderCondition>;

export const RENAME_LENDER_RULE_ACTION_TYPE = 'RENAME_LENDER_RULE' as const;
export const renameLenderRule = (lenderId: string, ruleId: string, name: string) => ({
  type: RENAME_LENDER_RULE_ACTION_TYPE,
  payload: {
    lenderId,
    ruleId,
    name
  }
});
export type RenameLenderRuleAction = ReturnType<typeof renameLenderRule>;

export const DELETE_LENDER_CONDITION_ACTION_TYPE = 'DELETE_LENDER_CONDITION' as const;
export const deleteLenderCondition = (lenderId: string, conditionId: string) => ({
  type: DELETE_LENDER_CONDITION_ACTION_TYPE,
  payload: {
    lenderId,
    conditionId
  }
});
export type DeleteLenderConditionAction = ReturnType<typeof deleteLenderCondition>;

export const DELETE_LENDER_RULE_ACTION_TYPE = 'DELETE_LENDER_RULE' as const;
export const deleteLenderRule = (lenderId: string, ruleId: string) => ({
  type: DELETE_LENDER_RULE_ACTION_TYPE,
  payload: {
    lenderId,
    ruleId
  }
});
export type DeleteLenderRuleAction = ReturnType<typeof deleteLenderRule>;

export const SAVE_LENDER_RULES_ACTION_TYPE = 'SAVE_LENDER_RULES';
export const saveLenderRulesAction = (lenderId: string) => ({
  type: SAVE_LENDER_RULES_ACTION_TYPE,
  payload: {
    lenderId
  }
});

export type SaveLenderRulesAction = ReturnType<typeof saveLenderRulesAction>;

export const SAVED_LENDER_RULES_ACTION_TYPE = 'SAVED_LENDER_RULES';
export const savedLenderRulesAction = (lenderId: string) => ({
  type: SAVED_LENDER_RULES_ACTION_TYPE,
  payload: {
    lenderId
  }
});

export type SavedLenderRulesAction = ReturnType<typeof savedLenderRulesAction>;
