import {
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpSlimLineAccordion,
  OwnUpSpinner,
  OwnUpUnderlineLink
} from '@rategravity/own-up-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { fetchLicensedStates } from '../../redux/actions';
import { startRulesValidation } from '../../redux/rules-validation/actions';
import {
  rulesValidationResults,
  rulesValidationRunning
} from '../../redux/rules-validation/selector';
import { hasErrorSelector } from '../../redux/selectors';

// Consider returning this as part of the payload when running the tests
const notionTestLink =
  'https://www.notion.so/own-up/d896663ebe484313b353bc47897cdd93?v=c005b6cca60a4b9c8fdb730a018de354';

export const Container = styled.div`
  margin: 24px 24px;
  table {
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  td {
    text-align: center;
  }
`;

export const RulesValidation = () => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<number | false>(false);
  const results = useSelector(rulesValidationResults);
  const testsRunning = useSelector(rulesValidationRunning);

  const onRunTests = useCallback(() => {
    dispatch(startRulesValidation());
  }, [dispatch]);

  const error = useSelector(hasErrorSelector);

  useEffect(() => {
    dispatch(fetchLicensedStates());
  }, [dispatch]);

  if (error) {
    return <Redirect to="/unauthorized" />;
  }

  const rows = useMemo(() => {
    const handleChange =
      (index: number) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? index : false);
      };

    return results.map((testResult, index) => {
      const formattedLead = Object.entries(testResult.test).map(([key, value], leadIndex) => {
        // Filter these out because they are too long and mess up the formatting
        return key === 'testExpectedLenders' || key === 'Expected Lender(s)' ? (
          <React.Fragment key={leadIndex} />
        ) : (
          <div key={leadIndex}>
            {key}: {value}
          </div>
        );
      });
      const matchRows = testResult.result.map((matchResult, matchIndex) => (
        <tr key={matchIndex}>
          <td>{matchResult.lenderId}</td>
          <td>{matchResult.score}</td>
          <td>{matchResult.leadCost}</td>
        </tr>
      ));

      return (
        <tr key={index}>
          <td>
            <OwnUpSlimLineAccordion
              title={testResult.name}
              $expandIconPosition="left"
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              {formattedLead}
            </OwnUpSlimLineAccordion>
          </td>
          <td>
            <table>
              <thead>
                <tr>
                  <th>Lender</th>
                  <th>Score</th>
                  <th>Lead Cost</th>
                </tr>
              </thead>
              <tbody>
                {matchRows.length === 0 ? (
                  <tr>
                    <td>(No matches)</td>
                  </tr>
                ) : (
                  matchRows
                )}
              </tbody>
            </table>
          </td>
        </tr>
      );
    });
  }, [results, expanded]);

  const Table = useMemo(() => {
    const TableComponent = () => (
      <table style={{ marginTop: '16px' }}>
        <thead>
          <tr>
            <th>Lead Description</th>
            <th>Lender Match Results</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
    return TableComponent;
  }, [rows]);

  return (
    <Container>
      <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
        Rules Validation
      </OwnUpHeadlineBook>
      <OwnUpFillButtonPrimary onClick={onRunTests} disabled={testsRunning}>
        Run Tests
      </OwnUpFillButtonPrimary>
      <div>
        <OwnUpUnderlineLink href={notionTestLink} target="_blank" rel="noreferrer">
          Source for Test Leads
        </OwnUpUnderlineLink>
      </div>
      {testsRunning ? (
        <div>
          Running tests ...
          <OwnUpSpinner />
        </div>
      ) : (
        <Table />
      )}
    </Container>
  );
};

export const RulesValidationPage = () => (
  <OwnUpGridWrapper>
    <OwnUpGridContainer variant="slim">
      <OwnUpGridItem xs={12}>
        <RulesValidation />
      </OwnUpGridItem>
    </OwnUpGridContainer>
  </OwnUpGridWrapper>
);
