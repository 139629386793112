import { AppState } from '../store';

export * from './lenders';

/**
 * Fetch the active state list.
 */
export const activeStateSelector = (state: AppState) => state.activeStates;

/**
 * Fetch the isLoading state.
 */
export const isLoadingSelector = (state: AppState): boolean =>
  state.loading.lenders || state.loading.activeStates;

/**
 * Fetch the hasError state.
 */
export const hasErrorSelector = (state: AppState) => state.error;

/**
 * Fetch the isSaving state.
 */
export const isSavingSelector = (state: AppState) => state.saving;

/**
 * Fetch the lastModifiedTime from state.
 */
export const lastModifiedTimeSelector = (state: AppState) => state.lastModifiedTime;

/**
 * Return whether or not unlicensed states should be shown
 */
export const isShowingUnlicensedStates = (state: AppState) => state.showUnlicensed;
