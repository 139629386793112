import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  RULES_VALIDATION_FAILED_TYPE,
  RULES_VALIDATION_FINISHED_TYPE,
  RulesValidationFailedAction,
  RulesValidationFinishedAction,
  START_RULES_VALIDATION_TYPE,
  StartRulesValidationAction
} from './actions';
import { initialState, RulesValidationState, TestResult } from './state';

export const rulesValidationReducer = composeReducers(
  withDefault<RulesValidationState>(initialState),
  ofType(
    START_RULES_VALIDATION_TYPE,
    (state: RulesValidationState, _: StartRulesValidationAction) => ({
      ...state,
      running: true,
      results: [] as TestResult[]
    })
  ),
  ofType(
    RULES_VALIDATION_FINISHED_TYPE,
    (state: RulesValidationState, { payload }: RulesValidationFinishedAction) => ({
      ...state,
      running: false,
      results: payload
    })
  ),
  ofType(
    RULES_VALIDATION_FAILED_TYPE,
    (state: RulesValidationState, _: RulesValidationFailedAction) => ({
      ...state,
      running: false,
      results: [] as TestResult[]
    })
  )
);
