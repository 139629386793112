import { faker } from '@faker-js/faker';
import { OwnUpSpinner } from '@rategravity/own-up-component-library';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import React from 'react';
import { schema } from './schema';
import { SentLead, useSendLead } from './use-send-lead';

const createLeadPayload = () => ({
  environment: 'staging',
  lead: {
    // -- Lead tracking information
    /* Where the lead came from (e.g., `rdc`, `Own Up`*/
    source: faker.helpers.arrayElement(['rdc', 'Own Up']),
    /* The unique id for this lead, per the source */
    sourceId: faker.datatype.uuid(),
    /* Unique Id for this lead */
    leadID: faker.datatype.uuid(),
    leadCost: Number(faker.commerce.price(20, 100)),

    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.email(),
    phoneNumber: faker.phone.number('###-###-####'),
    creditRating: faker.helpers.arrayElement(['Excellent', 'Good', 'Fair', 'Poor']),
    vaEligible: faker.datatype.boolean(),

    // -- Property information
    propertyStreet: faker.address.streetAddress(),
    propertyCity: faker.address.city(),
    propertyState: faker.address.stateAbbr(),
    propertyCounty: faker.address.county(),
    propertyZip: faker.address.zipCode(),
    /** How much the property is worth */
    propertyValue: Number(faker.commerce.price(200000, 1000000)),
    /** Type of property in question */
    propertyType: faker.helpers.arrayElement([
      'SingleFamily' as const,
      'MultiFamily' as const,
      'Condo' as const,
      'Manufactured' as const,
      'Townhome' as const,
      'NewConstruction' as const
    ]),
    /** How the property is being used */
    propertyUse: faker.helpers.arrayElement([
      'Primary' as const,
      'Secondary' as const,
      'Investment' as const
    ]),

    // -- Loan information
    /** Type of transaction */
    loanPurpose: faker.helpers.arrayElement(['Purchase' as const, 'Refinance' as const]),
    /** Size of the loan */
    loanAmount: Number(faker.commerce.price(150000, 900000)),

    // -- Universal Lead identification
    jornayaLeadId: faker.datatype.uuid()
  }
});

const ResultDisplay = ({ sent }: { sent: SentLead }) => {
  switch (sent.status) {
    case 'Sending':
      return <OwnUpSpinner />;
    case 'Success':
    case 'Failed':
      return (
        <div>
          <h2>{sent.status}</h2>
          <h3>Request</h3>
          <pre>{JSON.stringify(sent.request, undefined, 2)}</pre>
          <h3>Response</h3>
          <pre>{JSON.stringify(sent.response, undefined, 2)}</pre>
        </div>
      );
    default:
      return null;
  }
};

export const TestLeadPage = () => {
  const [data, setData] = React.useState(createLeadPayload);
  const [result, send] = useSendLead();
  return (
    <React.Fragment>
      <Form
        validator={validator}
        schema={schema}
        formData={data}
        onChange={(e) => setData(e.formData)}
        onSubmit={(e) => send(e.formData!)}
      />
      <ResultDisplay sent={result} />
    </React.Fragment>
  );
};
