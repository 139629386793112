import { OwnUpTextInput } from '@rategravity/own-up-component-library';
import React from 'react';

type StringEditorProps = {
  name: string;
  optional: boolean;
  data: string | undefined;
  onDataUpdate: (data: string | undefined) => void;
};

/**
 * Editor for a string value
 */
export const StringEditor = ({ name, optional, data, onDataUpdate }: StringEditorProps) => (
  <OwnUpTextInput
    label={name}
    value={data ?? ''}
    onChange={(event) => {
      const v = event.target.value;
      if (v === '' && optional) {
        onDataUpdate(undefined);
      } else {
        onDataUpdate(v);
      }
    }}
  />
);
