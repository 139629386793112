import * as t from 'type-shift';
import { LenderBudget, lenderBudgetConverter } from '../../redux/lender-configuration/types';
import { loggedInFetch } from '../authentication';

const lenderBudgetsConverter = t.array(lenderBudgetConverter);

export const fetchAllLenderBudgetsApi = async () => {
  const response = await loggedInFetch('/api/lender/configuration/budgets/all');
  if (response.ok) {
    const json = await response.json();
    return lenderBudgetsConverter(json.result).sort((a, b) => {
      if (a.lenderId < b.lenderId) {
        return -1;
      }
      if (b.lenderId > a.lenderId) {
        return 1;
      }
      return 0;
    });
  }

  throw new Error(
    `Error fetching lender budgets, status: ${response.status}, text: ${response.statusText}`
  );
};

export const updateLenderBudgetApi = async ({ lenderId, ...body }: LenderBudget) => {
  const response = await loggedInFetch(`/api/lender/configuration/budgets/${lenderId}`, {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(body)
  });
  if (response.ok) {
    const json = await response.json();
    return lenderBudgetConverter(json.result);
  }

  throw new Error(
    `Error updating lender budget status: ${response.status}, text: ${response.statusText}`
  );
};

const enabledLendersConverter = t.array(t.string);

export const fetchEnabledLendersApi = async () => {
  const response = await loggedInFetch(`/api/lender/configuration/enable`);
  if (response.ok) {
    const json = await response.json();
    return enabledLendersConverter(json.result);
  }

  throw new Error(
    `Error fetching enabled lenders, status: ${response.status}, text: ${response.statusText}`
  );
};

export const updateLenderEnabledApi = async (lenderId: string, enabled: boolean) => {
  const response = await loggedInFetch(`/api/lender/configuration/enable`, {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify([
      {
        lenderId,
        enabled
      }
    ])
  });
  if (response.ok) {
    return true;
  }

  throw new Error(
    `Error updating lender enabled, status: ${response.status}, text: ${response.statusText}`
  );
};
