import { OwnUpBox, OwnUpText } from '@rategravity/own-up-component-library-legacy';
import React from 'react';

export const renderHeaders = (headers: string[]) =>
  headers.map((header, index) => {
    const style = index === 0 ? { fontSize: '12px', textAlign: 'start' as const } : {};
    return (
      <OwnUpBox style={style} key={`h-${index}`}>
        <OwnUpText variant="thin">{header}</OwnUpText>
      </OwnUpBox>
    );
  });
