import * as t from 'type-shift';
import { TestResult } from '../../redux/rules-validation/state';
import { loggedInFetch } from '../authentication';

const resultsConverter = t.shape({
  result: t.array(
    t.shape<TestResult>({
      name: t.forPath([t.ParentPath, 'test', 'testName'], t.string),
      test: t.shape({}),
      result: t
        .array(
          t.strict({
            lenderId: t.string,
            score: t.number,
            leadCost: t.number
          })
        )
        .or(t.shape({ error: t.boolean }).pipe(() => []))
    })
  )
});

export const runRulesValidation = async () => {
  const response = await loggedInFetch(`/api/lender/configuration/rules-validation`, {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({})
  });
  if (response.ok) {
    const json = await response.json();
    return resultsConverter(json).result;
  }

  throw new Error(
    `Error running rules validation, status: ${response.status}, text: ${response.statusText}`
  );
};
