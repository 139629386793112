import { OwnUpDropdown, OwnUpMenuItem } from '@rategravity/own-up-component-library';
import { NestedCondition, TopLevelCondition } from 'json-rules-engine';
import React from 'react';
import { FactInfo } from '../../modules/rules/schema';
import { BooleanEditor } from './boolean-editor';

type ConditionEditorProps = {
  condition: TopLevelCondition;
  facts: FactInfo[];
  conditions: string[];
  validationErrors: Map<string, string>;
  onConditionUpdate: (condition: TopLevelCondition) => void;
};

/**
 * Renders the top level All/Any selector for the rules.
 */
export const ConditionEditor = ({
  condition,
  facts,
  conditions,
  validationErrors,
  onConditionUpdate
}: ConditionEditorProps) => (
  <div>
    <BooleanEditor
      path={`/${'all' in condition ? 'all' : 'any'}`}
      validationErrors={validationErrors}
      canCollapse={false}
      conditionSelect={
        <OwnUpDropdown
          value={'all' in condition ? 'all' : 'any'}
          onChange={(event) => {
            if (event.target.value === 'all') {
              onConditionUpdate({ all: (condition as { any: NestedCondition[] }).any });
            } else {
              onConditionUpdate({ any: (condition as { all: NestedCondition[] }).all });
            }
          }}
        >
          <OwnUpMenuItem value="all">All</OwnUpMenuItem>
          <OwnUpMenuItem value="any">Any</OwnUpMenuItem>
        </OwnUpDropdown>
      }
      removeButton={null}
      condition={condition as any}
      facts={facts}
      conditions={conditions}
      onConditionUpdate={onConditionUpdate}
    />
  </div>
);
