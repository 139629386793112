import { createOwnUpComponent, OwnUpButton } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useDispatch } from 'react-redux';
import { syncProdDataAction } from '../../redux/actions';

const StyleButton = createOwnUpComponent(OwnUpButton, {
  transform: 'scale(1)',
  animation: 'pulse 2s infinite',
  fontSize: '20px',
  backgroundColor: '#43EE91',
  color: '#800080',
  marginTop: '12px',
  /* eslint-disable @typescript-eslint/naming-convention */
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 #8EF5BD'
    },

    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px #D9FDE9'
    },

    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 #143F3D'
    }
  }
  /* eslint-enable @typescript-eslint/naming-convention */
});

export const SyncButton = () => {
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(syncProdDataAction());
  };
  return <StyleButton onClick={handleButtonClick}>Sync with Production</StyleButton>;
};
