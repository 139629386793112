import { NestedCondition } from 'json-rules-engine';
import React from 'react';
import { AllConditionsNode } from './all-conditions-node';
import { AnyConditionsNode } from './any-conditions-node';
import { ConditionPropertiesNode } from './condition-properties-node';
import { ConditionReferenceNode } from './condition-reference-node';
import { NotConditionsNode } from './not-conditions-node';

export const camelCaseToStartCase = (input: string): string => {
  const startCase = input.replace(/([a-z])([A-Z])/g, '$1 $2');
  return startCase.charAt(0).toUpperCase() + startCase.slice(1);
};

export const mapNestedConditions = (data: NestedCondition[]) => {
  const children: React.ReactNode[] = data.map((condition, index) => {
    if ('all' in condition) {
      return <AllConditionsNode key={index} data={condition.all} />;
    } else if ('any' in condition) {
      return <AnyConditionsNode key={index} data={condition.any} />;
    } else if (
      'not' in condition &&
      'condition' in condition.not &&
      typeof condition.not.condition === 'string'
    ) {
      return <NotConditionsNode key={index} data={condition.not} />;
    } else if ('fact' in condition && 'operator' in condition && 'value' in condition) {
      return <ConditionPropertiesNode key={index} data={condition} />;
    } else if ('condition' in condition) {
      return <ConditionReferenceNode key={index} data={condition} />;
    }
    return null;
  });
  return children;
};
