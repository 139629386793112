import { createSelector } from 'reselect';
import { UpdateLenderRulesPayload } from '../../modules/api/lender-rules';
import { AppState } from '../store';
import { getInUseConditions } from './is-in-use';

/**
 * Return all shared (named) conditions for lender
 */
export const lenderConditionsSelector = (state: AppState, { lenderId }: { lenderId: string }) => {
  const conditions = state.lenderRules[lenderId]?.conditions;
  return Object.entries(conditions ?? {})
    .filter(([_, v]) => v.status !== 'deleted')
    .map(([conditionId, condition]) => ({ ...condition, conditionId }));
};

export const lenderRulesSelector = (state: AppState, { lenderId }: { lenderId: string }) => {
  const rules = state.lenderRules[lenderId]?.rules;
  return Object.entries(rules ?? {})
    .filter(([_, v]) => v.status !== 'deleted')
    .map(([ruleId, rule]) => ({ ...rule, ruleId }));
};

export const lenderConditionSelector = (
  state: AppState,
  { lenderId, conditionId }: { lenderId: string; conditionId: string }
) => {
  return state.lenderRules[lenderId]?.conditions[conditionId];
};

export const inUseConditionsSelector = (state: AppState, { lenderId }: { lenderId: string }) => {
  const set = new Set<string>();
  Object.values(state.lenderRules[lenderId]?.rules ?? {}).forEach((rule) =>
    getInUseConditions(rule.conditions, set)
  );
  Object.values(state.lenderRules[lenderId]?.conditions ?? {}).forEach((condition) =>
    getInUseConditions(condition.conditions, set)
  );
  return Object.entries(state.lenderRules[lenderId]?.conditions ?? {})
    .filter(([, { name }]) => set.has(name))
    .map(([conditionId]) => conditionId);
};

export const lenderRuleSelector = (
  state: AppState,
  { lenderId, ruleId }: { lenderId: string; ruleId: string }
) => {
  return state.lenderRules[lenderId]?.rules[ruleId];
};

/**
 * Gets the payload for the updateLenderRules lambda
 */
export const updatePayloadSelector = (
  state: AppState,
  { lenderId }: { lenderId: string }
): UpdateLenderRulesPayload => {
  const lender = state.lenderRules[lenderId];
  const rules = Object.values(lender.rules)
    .filter((rule) => rule.status === 'edited')
    .map((rule) => [rule.name, rule]);
  const editedConditions = Object.values(lender.conditions)
    .filter((condition) => condition.status === 'edited')
    .map(({ name, conditions }) => [name, conditions]);
  return {
    lenderId,
    rules: Object.fromEntries(rules),
    conditions: Object.fromEntries(editedConditions)
  };
};

export const deletePayloadSelector = (state: AppState, { lenderId }: { lenderId: string }) => {
  const lender = state.lenderRules[lenderId];
  const deletedRules = Object.values(lender.rules)
    .filter((rule) => rule.status === 'deleted')
    .map((rule) => rule.name);
  const deletedConditions = Object.values(lender.conditions)
    .filter((condition) => condition.status === 'deleted')
    .map((condition) => condition.name);
  return {
    lenderId,
    rules: deletedRules,
    conditions: deletedConditions
  };
};

export const pendingSaveSelector = createSelector(
  updatePayloadSelector,
  deletePayloadSelector,
  (updatePayload, deletePayload) => {
    return (
      Object.values(updatePayload.rules || {}).length > 0 ||
      Object.values(updatePayload.conditions || {}).length > 0 ||
      deletePayload.rules.length > 0 ||
      deletePayload.conditions.length > 0
    );
  }
);

export const savingSelector = (state: AppState, { lenderId }: { lenderId: string }) =>
  state.lenderRules?.[lenderId]?.savingRules;

/**
 * Return the loading state for lender rules
 */
export const lenderRulesLoadingSelector = (state: AppState, { lenderId }: { lenderId: string }) => {
  return state.lenderRules[lenderId]?.loading ?? true;
};

/**
 * Return the error state for lender rules
 */
export const lenderRulesErrorSelector = (state: AppState, { lenderId }: { lenderId: string }) => {
  return state.lenderRules[lenderId]?.error ?? false;
};
