import { NestedCondition } from 'json-rules-engine';

export const getInUseConditions = (conditions: NestedCondition, accumulator: Set<string>): void => {
  if ('condition' in conditions) {
    accumulator.add(conditions.condition);
  } else if ('all' in conditions) {
    conditions.all.forEach((condition) => getInUseConditions(condition, accumulator));
  } else if ('any' in conditions) {
    conditions.any.forEach((condition) => getInUseConditions(condition, accumulator));
  } else if ('not' in conditions) {
    getInUseConditions(conditions.not, accumulator);
  }
};
