/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { OwnUpFillButtonSecondary, OwnUpIconButton } from '@rategravity/own-up-component-library';
import { CopyIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/copy';
import { EditIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/edit';
import React from 'react';
import styled from 'styled-components';
import { formatEventProps } from '../../modules/rules/format-rules';
import { LenderState } from '../../redux/lender-rules/state';
import { ConditionSummary } from './condition-summary';

const ListWrapper = styled.ul`
  list-style: none;
  padding: 0;
`;

type LenderCondtionsListProps = {
  data: (LenderState['conditions'][string] & { conditionId: string })[];
  inUseConditions: string[];
  onEdit?: (conditionId: string) => void;
  onDelete: (conditionId: string) => void;
};

export const LenderConditionsList = ({
  data,
  inUseConditions,
  onEdit,
  onDelete
}: LenderCondtionsListProps) => {
  return (
    <ListWrapper>
      {data.map((condition) => (
        <ConditionSummary
          key={condition.conditionId}
          name={condition.name}
          condition={condition}
          enabled={true}
        >
          {onEdit && (
            <OwnUpIconButton
              icon={EditIcon}
              name="Edit"
              onClick={() => onEdit(condition.conditionId)}
            />
          )}
          <OwnUpFillButtonSecondary
            disabled={inUseConditions.includes(condition.conditionId)}
            onClick={() => onDelete(condition.conditionId)}
          >
            Delete
          </OwnUpFillButtonSecondary>
        </ConditionSummary>
      ))}
    </ListWrapper>
  );
};

type LenderRuleListProps = {
  data: (LenderState['rules'][string] & { ruleId: string })[];
  onEdit: (ruleId: string) => void;
  onClone: (rule: LenderState['rules'][string]) => void;
  onDelete: (ruleId: string) => void;
};

const StyledTable = styled.table`
  width: auto;
  td {
    white-space: nowrap;
    text-align: left;
    padding: 0 8px;
  }
  td:last-child {
    width: 100%;
  }
`;

export const LenderRuleList = ({ data, onEdit, onClone, onDelete }: LenderRuleListProps) => {
  return (
    <ListWrapper>
      <StyledTable>
        <thead>
          <th>Rule set(s)</th>
          <th>Enabled?</th>
          <th>Rule</th>
        </thead>
        <tbody>
          {data.map((rule) => (
            <ConditionSummary
              key={rule.ruleId}
              name={rule.name}
              condition={rule}
              enabled={rule.enabled}
            >
              <div>{`Emit ${formatEventProps(rule.event)}`}</div>
              <OwnUpIconButton icon={EditIcon} name="Edit" onClick={() => onEdit(rule.ruleId)} />
              <OwnUpIconButton icon={CopyIcon} name="Clone" onClick={() => onClone(rule)} />
              <OwnUpFillButtonSecondary onClick={() => onDelete(rule.ruleId)}>
                Delete
              </OwnUpFillButtonSecondary>
            </ConditionSummary>
          ))}
        </tbody>
      </StyledTable>
    </ListWrapper>
  );
};
