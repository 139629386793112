import { OwnUpButton, OwnUpHeader, OwnUpText } from '@rategravity/own-up-component-library-legacy';
import React from 'react';

export const UnauthHeader = ({
  login,
  redirectPath
}: {
  login: (redirect: string) => Promise<void>;
  redirectPath?: string;
}) => (
  <>
    <OwnUpHeader variant="title">Your session has expired.</OwnUpHeader>
    <OwnUpText>
      If you are not automatically redirected, you can click on the button below to login again.
    </OwnUpText>
    <OwnUpButton onClick={() => void login(window.location.origin + '/auth' + redirectPath)}>
      Click here to try signing in again.
    </OwnUpButton>
  </>
);
