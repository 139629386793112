import { OwnUpIcon } from '@rategravity/own-up-component-library';
import { ErrorLightIcon } from '@rategravity/own-up-component-library/icon-library/notification-icons/error-light';
import React from 'react';
import styled from 'styled-components';

const ErrorBox = styled.div`
  grid-column: span 5;
  border-top: 4px solid red;
  color: red;
  margin-top: -14px;
  z-index: 255;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
`;

export const ErrorText = ({ error }: { error?: string }) => {
  if (!error) {
    return null;
  }

  return (
    <ErrorBox>
      <OwnUpIcon icon={ErrorLightIcon} />
      {error}
    </ErrorBox>
  );
};
