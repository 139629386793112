import {
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpTextInput
} from '@rategravity/own-up-component-library';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ConditionEditor } from '../../components/condition-editor';
import { facts } from '../../modules/rules/schema';
import { DEFAULT_LENDER_ID } from '../../modules/rules/schema/constants';
import { editLenderCondition, renameLenderCondition } from '../../redux/lender-rules/actions';
import {
  lenderConditionSelector,
  lenderConditionsSelector
} from '../../redux/lender-rules/selector';
import { AppState } from '../../redux/store';
import { Container } from './budgets';

interface LenderConditionEditorParams {
  lenderId: string;
  conditionId: string;
  onDone: () => void;
}

export const LenderConditionEditorPage = ({
  lenderId,
  conditionId,
  onDone
}: LenderConditionEditorParams) => {
  const dispatch = useDispatch();

  const conditions = useSelector(
    createSelector(
      (state: AppState) => lenderConditionsSelector(state, { lenderId }),
      (state: AppState) => lenderConditionsSelector(state, { lenderId: DEFAULT_LENDER_ID }),
      (conditionsForLender, defaultConditions) =>
        Array.from(
          new Set([
            ...conditionsForLender.map(({ name }) => name),
            ...defaultConditions.map(({ name }) => name)
          ])
        )
    )
  );
  const condition = useSelector((state: AppState) =>
    lenderConditionSelector(state, { lenderId, conditionId })
  ) ?? { name: '', conditions: { all: [] } };

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            {`Condition Editor: ${lenderId} / ${condition.name}`}
          </OwnUpHeadlineBook>
          <Container>
            <div>
              <OwnUpTextInput
                label="Name"
                value={condition.name}
                onChange={(event) =>
                  dispatch(renameLenderCondition(lenderId, conditionId, event.target.value))
                }
              />
              <br />
              <ConditionEditor
                facts={facts}
                conditions={conditions}
                condition={condition.conditions}
                validationErrors={new Map()}
                onConditionUpdate={(update) =>
                  dispatch(editLenderCondition(lenderId, conditionId, update))
                }
              />
              <OwnUpFillButtonPrimary onClick={onDone}>Done</OwnUpFillButtonPrimary>
            </div>
          </Container>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
