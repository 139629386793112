import isEqual from 'lodash.isequal';
import { LenderColumnState } from '../../components/lender-table/properties';
import { updateConstraints } from '../../modules/update-constraints';
import {
  CREATE_LENDERS,
  CreateLendersAction,
  SAVE_LENDER,
  SaveLenderAction,
  UPDATE_LENDER,
  UpdateLenderAction
} from '../actions/action-types';
import { LenderState } from '../store/lender/types';

const intialLenderState = {
  byId: {},
  allIds: []
};

type LenderActions = CreateLendersAction | UpdateLenderAction | SaveLenderAction;

const createLenders = (state: LenderState, action: CreateLendersAction) => {
  const { payload } = action;
  const newState = {
    byId: payload
      .map((lender) => ({ [lender.id]: lender }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), state.byId),
    allIds: [...state.allIds, ...payload.map((lender) => lender.id)]
  };
  return newState;
};

// TODO: Institute a draft system similar to Internal Dashboard,
// to eliminate redundant changes (e.g., if someone makes a change
// and changes it back).
export const updateLenderState = (lenderState: LenderState, action: UpdateLenderAction) => {
  const {
    payload: { id, state: stateToUpdate, setting }
  } = action;

  const {
    enabledStates: existingEnabledStates = [],
    licensedStates: existingLicensedStates,
    autoQuoteConstraints: existingAutoQuoteConstraints = []
  } = lenderState.byId[id].states;

  const newLenderStates = {
    enabledStates: [...existingEnabledStates],
    licensedStates: [...existingLicensedStates],
    autoQuoteConstraints: [...existingAutoQuoteConstraints]
  };

  const newEnabledStates = [
    ...existingEnabledStates,
    ...(existingLicensedStates.indexOf(stateToUpdate) > -1 &&
    existingEnabledStates.indexOf(stateToUpdate) < 0
      ? [stateToUpdate]
      : [])
  ];

  switch (setting) {
    case LenderColumnState.DISABLED:
      newLenderStates.enabledStates = [...existingEnabledStates.filter((s) => s !== stateToUpdate)];
      break;
    case LenderColumnState.MANUAL:
      newLenderStates.enabledStates = newEnabledStates;

      if (lenderState.byId[id].autoQuotable) {
        newLenderStates.autoQuoteConstraints = updateConstraints(
          existingAutoQuoteConstraints,
          stateToUpdate,
          [{ state: stateToUpdate }, false]
        );
      }
      break;
    case LenderColumnState.AUTOQUOTABLE:
      if (lenderState.byId[id].autoQuotable) {
        newLenderStates.enabledStates = newEnabledStates;

        newLenderStates.autoQuoteConstraints = updateConstraints(
          existingAutoQuoteConstraints,
          stateToUpdate
        );
      }
      break;

    case LenderColumnState.AQ_PURCHASE:
      if (lenderState.byId[id].autoQuotable) {
        newLenderStates.enabledStates = newEnabledStates;

        newLenderStates.autoQuoteConstraints = updateConstraints(
          existingAutoQuoteConstraints,
          stateToUpdate,
          [{ state: stateToUpdate, scenario: 'refinance' }, false]
        );
      }
      break;

    case LenderColumnState.AQ_NON_INVESTMENT:
      if (lenderState.byId[id].autoQuotable) {
        newLenderStates.enabledStates = newEnabledStates;

        newLenderStates.autoQuoteConstraints = updateConstraints(
          existingAutoQuoteConstraints,
          stateToUpdate,
          [{ state: stateToUpdate, occupancy: 'InvestmentProperty' }, false]
        );
      }
      break;

    case LenderColumnState.AQ_REFINANCE:
      if (lenderState.byId[id].autoQuotable) {
        newLenderStates.enabledStates = newEnabledStates;

        newLenderStates.autoQuoteConstraints = updateConstraints(
          existingAutoQuoteConstraints,
          stateToUpdate,
          [{ state: stateToUpdate, scenario: 'purchase' }, false]
        );
      }
      break;
  }

  return !isEqual(newLenderStates, lenderState.byId[id].states)
    ? {
        byId: {
          ...lenderState.byId,
          [id]: {
            ...lenderState.byId[id],
            states: {
              ...lenderState.byId[id].states,
              ...newLenderStates
            },
            fieldStatus: {
              states: 'dirty' as const
            }
          }
        },
        allIds: lenderState.allIds
      }
    : lenderState;
};

export const saveLender = (state: LenderState, action: SaveLenderAction) => {
  const {
    payload: { lender }
  } = action;

  return {
    byId: {
      ...state.byId,
      [lender.id]: {
        ...state.byId[lender.id],
        ...lender,
        fieldStatus: {
          states: 'saving' as const
        }
      }
    },
    allIds: state.allIds
  };
};

export const lenderReducer = (
  state: LenderState = intialLenderState,
  action: LenderActions
): LenderState => {
  switch (action.type) {
    case CREATE_LENDERS:
      return createLenders(intialLenderState, action);
    case UPDATE_LENDER:
      return updateLenderState(state, action);
    case SAVE_LENDER:
      return saveLender(state, action);
    default:
      return state;
  }
};
