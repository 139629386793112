import { createInternalAuthLoggedInFetch } from '@rategravity/widgets-react/modules/auth/internal/create-internal-auth-logged-in-fetch';

/**
 * Groups who can edit the lenders.
 */
export const EDITOR_GROUPS = ['dev', 'lender-management'];

const doFetch = createInternalAuthLoggedInFetch({});
export const loggedInFetch = (info: RequestInfo, headers?: RequestInit) =>
  doFetch(info, headers || { headers: {} }, async (r) => {
    window.location.href = '/unauthorized';
    return r;
  });
